import purchaseSeriesListActionTypes from "./PurchaseSeriesList.types"

export const purchaseSeriesListStart = (payload) => ({
  type: purchaseSeriesListActionTypes.API_CALL_START,
  payload,
})

export const purchaseSeriesListSuccess = (payload) => ({
  type: purchaseSeriesListActionTypes.API_CALL_SUCCESS,
  payload,
})

export const purchaseSeriesListFailure = (payload) => ({
  type: purchaseSeriesListActionTypes.API_CALL_FAILURE,
  payload,
})
