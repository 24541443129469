import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectLoginStatus } from "../../store/signIn/signIn.selectors";
import { userLogin, userLoginFailure } from "../../store/signIn/signIn.actions";
import {
  setEmployeeData,
  setSigninRedirectPath,
} from "../../store/home/home.actions";
import validator from "validator";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Backdrop from "@material-ui/core/Backdrop";
import { ReactComponent as DOT } from "../../assets/images/DOT.svg";
import { ReactComponent as VeuitLogo } from "../../assets/images/veuit-logo.svg";
import { ReactComponent as BLueeye } from "../../assets/images/blue-eye.svg";
import { ReactComponent as Whiteeye } from "../../assets/images/white-eye.svg";
import { selectSigninRedirectPath } from "../../store/home/home.selectors";
import Cookies from "js-cookie";

const mapStateToProps = (state) => {
  return {
    selectLoginStatus: selectLoginStatus(state),
    selectSigninRedirectPath: selectSigninRedirectPath(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      userLogin,
      userLoginFailure,
      setEmployeeData,
      setSigninRedirectPath,
    },
    dispatch
  ),
});

var isNotifiaction = 0;

export class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      username: "",
      password: "",
      passwordType: "password",
      windowSerach: "",
      isChannelLogin: 0,
      domainURL: "",
      searchparam: {},
      isAutoLogin: 0,
      userToken: "",
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const location = window.location.search;
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("request")) {
      const searchparam = JSON.parse(window.atob(queryParams.get("request")));
      if (searchparam.requestURL != null && searchparam.domainUrl != null) {
        this.setState({
          isChannelLogin: 1,
          searchparam: searchparam,
        });
      } else {
        NotificationManager.warning("Somthing Went Wrong.", "", 2000);
      }
    } else if (
      queryParams.get("requestURL") != null &&
      queryParams.get("domainURL") != null
    ) {
      this.setState({
        username: "",
        password: "",
        domainURL: queryParams.get("domainURL"),
        windowSerach: location.replace("?requestURL", "&redirectURL"),
        isChannelLogin: 1,
      });
    } else {
      if (localStorage.getItem("employeeId") != null) {
        this.props.history.push("/");
      } else {
        this.setState({
          username: "",
          password: "",
          windowSerach: "",
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectLoginStatus, userLoginFailure } = this.props;
    if (isNotifiaction === 1) {
      if (selectLoginStatus.success != null) {
        if (selectLoginStatus.success) {
          isNotifiaction = 0;
          NotificationManager.success("Login Successfully.", "", 2000);
          if (
            this.state.windowSerach &&
            this.state.windowSerach != null &&
            this.state.windowSerach !== "" &&
            this.state.domainURL &&
            this.state.domainURL != null &&
            this.state.domainURL !== ""
          ) {
            if (
              selectLoginStatus.employeeData != null &&
              selectLoginStatus.employeeData.employeeId != null
            ) {
              window.location.replace(
                this.state.domainURL +
                  "/session?employeeId=" +
                  selectLoginStatus.employeeData.employeeId +
                  "&allowAutoChannelCreation=0&token=" +
                  selectLoginStatus.employeeData.userToken +
                  this.state.windowSerach
              );
            }
          } else if (
            this.state.searchparam.requestURL != null &&
            this.state.searchparam.domainUrl != null
          ) {
            if (
              selectLoginStatus.employeeData != null &&
              selectLoginStatus.employeeData.employeeId != null
            ) {
              var params = this.state.searchparam;
              params.token = selectLoginStatus.employeeData.userToken;
              params.employeeId = selectLoginStatus.employeeData.employeeId;
              params.allowAutoChannelCreation = 0;
              var encreptedData = window.btoa(JSON.stringify(params));
              window.location.replace(
                this.state.searchparam.domainUrl +
                  "/session?redirect=" +
                  encreptedData
              );
            }
          } else {
            var employeeData = localStorage.getItem("employeeData");
            if (employeeData != null && employeeData !== "") {
              try {
                employeeData = JSON.parse(employeeData);
              } catch (err) {}
            }
            this.props.setEmployeeData(employeeData ?? null);

            this.timeoutHandle = setTimeout(() => {
              this.setState({ isLoading: 0 });

              if (this.props.selectSigninRedirectPath != null) {
                const path = this.props.selectSigninRedirectPath;
                this.props.setSigninRedirectPath({
                  signinRedirectPath: null,
                });
                this.props.history.push(path);
              } else {
                this.props.history.push("/");
              }
            }, 3000);
            userLoginFailure({
              loginSuccess: false,
              loading: false,
              err: null,
            });
          }
        } else {
          if (selectLoginStatus.error != null) {
            NotificationManager.error(selectLoginStatus.error, "", 2000);
            isNotifiaction = 0;
            this.timeoutHandle = setTimeout(() => {
              this.setState({ isLoading: 0 });
            }, 3000);
            userLoginFailure({
              loginSuccess: false,
              loading: false,
              err: null,
            });
          }
        }
      }
    }
  }

  handleUsernameChange(event) {
    const removeExtraSpace = (s) => s.replace(/\s/g, "");
    this.setState({ username: removeExtraSpace(event.target.value) });
  }

  handlePasswordChange(event) {
    const removeExtraSpace = (s) => s.replace(/\s/g, "");
    this.setState({ password: removeExtraSpace(event.target.value) });
  }

  signIn = () => {
    const { userLogin } = this.props;
    if (this.state.username === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000);
    } else if (!validator.isEmail(this.state.username)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000);
    } else if (this.state.password === "") {
      NotificationManager.warning("Please Enter Password.", "", 2000);
    } else if (this.state.password !== "" && this.state.username !== "") {
      const payload = {
        username: this.state.username,
        password: this.state.password,
        isChannelLogin: this.state.isChannelLogin,
        isAutoLogin: this.state.isAutoLogin,
        userToken: this.state.userToken,
      };
      isNotifiaction = 1;
      this.setState({ isLoading: 1 });
      userLogin(payload);
    }
  };

  showPassword = () => {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  };

  signInValidate = () => {
    if (!validator.isEmail(this.state.username) || this.state.password === "") {
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <section className="sign-in-page">
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          ""
        )}
        <Container id="container-signin">
          <Row className="justify-content-center align-items-center height-self-center m-0">
            <div id="set-sign-in1">
              <Col
                className="align-self-center set-sign-height"
                id="set-sign-in"
              >
                <div className="sign-user_card ">
                  <div className="sign-in-page-data">
                    <div className="sign-in-from w-100 m-auto">
                      {/* <h3 className="mb-3 text-center">Sign in</h3> */}
                      <div className="sign-in-logo">
                        <Link to="/">
                          <VeuitLogo id="info-logopages" />
                        </Link>
                      </div>
                      <div className="form-bg-mobile">
                        <div className="form-bg-mobile1">
                          <Form
                            id="set-input-width"
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {/* <p className="login-acc">Log in to your account</p> */}
                            <Form.Group>
                              <Form.Control
                                type="email"
                                className="form-control mb-0 sign-in-input"
                                id="email"
                                placeholder="Email"
                                value={this.state.username}
                                onChange={this.handleUsernameChange}
                                autoComplete="off"
                                required
                              />
                            </Form.Group>
                            <Form.Group>
                              <div className="input-group">
                                <Form.Control
                                  type={this.state.passwordType}
                                  className="form-control mb-0 sign-in-input-pwd"
                                  id="password"
                                  placeholder="Password"
                                  value={this.state.password}
                                  onChange={this.handlePasswordChange}
                                  required
                                />
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text sign-pwd-i-icon"
                                    id="inputGroupPrepend2"
                                    onClick={this.showPassword}
                                    style={{
                                      backgroundColor: "#141414",
                                      border: "1px solid #404043",
                                      color: "#fff",
                                    }}
                                  >
                                    {/* <i className={this.state.passwordType === 'password' ? 'd-eye-off' : 'd-eye-on'}></i> */}
                                    {this.state.passwordType === "password" ? (
                                      <BLueeye />
                                    ) : (
                                      <Whiteeye />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </Form.Group>
                            <div className="sign-in-forgot-pwd">
                              <div className="d-flex justify-content-center links">
                                <Link
                                  to="/authentication/forgot-password"
                                  className="f-link set-veuit-signin"
                                  id="set-veuit-signin"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                              <div className="sign-vector">
                                <DOT className="sign-vector-img1" />
                              </div>
                              <div className="d-flex justify-content-center links">
                                <Link
                                  to="/authentication/sign-up"
                                  className="ml-2 set-veuit-signin"
                                >
                                  Sign up for VEUIT
                                </Link>
                              </div>
                            </div>
                            <div className="sign-info">
                              <Button
                                type="submit"
                                onClick={this.signIn}
                                className={`btn ${
                                  this.signInValidate() ? "" : "disabled"
                                }`}
                                id="sign-inbtn"
                              >
                                Login
                              </Button>
                              {/*<div className="custom-control custom-checkbox d-inline-block">
                                                    <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                    <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                                                </div>*/}
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
