import { useEffect, useState } from "react";
import {
  PaymentElement,
  // LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Form, Modal } from "react-bootstrap";
import Backdrop from "@material-ui/core/Backdrop";
//import Loader from "../loader/loader";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  // const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [loadErrorMessage, setLoadErrorMessage] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isdontSaveCard, setIsdontSaveCard] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }

  //   // Retrieve the "payment_intent_client_secret" query parameter appended to
  //   // your return_url by Stripe.js
  //   // const clientSecret = new URLSearchParams(window.location.search).get(
  //   //   'payment_intent_client_secret'
  //   // );

  //   // Retrieve the PaymentIntent
  //   stripe
  //     .retrievePaymentIntent(props.clientSecret)
  //     .then(({ paymentIntent }) => {
  //       // Inspect the PaymentIntent `status` to indicate the status of the payment
  //       // to your customer.
  //       //
  //       // Some payment methods will [immediately succeed or fail][0] upon
  //       // confirmation, while others will first enter a `processing` state.
  //       //
  //       // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

  //       switch (paymentIntent.status) {
  //         case "succeeded":
  //           NotificationManager.warning("Payment succeeded!", "", 2000);
  //           setMessage("Success! Payment received.");
  //           break;

  //         case "processing":
  //           NotificationManager.warning(
  //             "Payment processing. We'll update you when payment is received.",
  //             "",
  //             2000
  //           );
  //           setMessage(
  //             "Payment processing. We'll update you when payment is received."
  //           );
  //           break;

  //         case "requires_payment_method":
  //           // Redirect your user back to your payment page to attempt collecting
  //           // payment again
  //           NotificationManager.warning(
  //             "Payment failed. Please try another payment method.",
  //             "",
  //             2000
  //           );
  //           setMessage("Payment failed. Please try another payment method.");
  //           break;

  //         default:
  //           NotificationManager.warning("Something went wrong.", "", 2000);
  //           setMessage("Something went wrong.");
  //           break;
  //       }
  //     });
  // }, [stripe]);

  const handledontsaveCard = async () => {
    setIsLoading(true);
    var dontSaveCard = isdontSaveCard === 1 ? 0 : 1;
    setIsdontSaveCard(dontSaveCard);
    console.log(dontSaveCard);

    UserService.updatePaymentIntent({
      paymentIntentId: props.paymentIntentId,
      setupFuturUsage: dontSaveCard === 0 ? 1 : 0,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
    })
      .then((res) => {
        if (res.error != null) {
          setIsLoading(false);
        } else {
          if (res["status"]) {
            setIsdontSaveCard(isdontSaveCard === 1 ? 0 : 1);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    // if (isdontSaveCard === 1) {
    //   await handledontsaveCard();
    // }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:
          window.location.origin +
          "/authentication/payment-status/" +
          props.orderId,
        // return_url:props.returnUrl + "/authentication/payment-status/" + props.orderId,
        // return_url: "http://localhost:3000/movie-details/25013",
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      NotificationManager.warning(error.message, "", 2000);
      setMessage(error.message);
    } else {
      NotificationManager.warning("An unexpected error occurred.", "", 2000);
      setMessage("An unexpected error occurred.");
    }

    // if (error) {
    //   // This point will only be reached if there is an immediate error when
    //   // confirming the payment. Show error to your customer (for example, payment
    //   // details incomplete)
    //   setErrorMessage(error.message);
    // } else {
    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    // }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
      /> */}
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onLoadError={(e) => {
          setLoadErrorMessage("ERROR WHILE INITIATING PAYMENT");
          setIsInitializing(false);
        }}
        onLoaderStart={(e) => {
          console.log(e);
        }}
        onReady={() => {
          setIsInitializing(false);
        }}
      />

      {/* <Form.Group className="form-group">
        <Form.Label
          htmlFor="exampleFormControlSelect2"
          className="payment-form-label"
        >
          Cardholder Name
        </Form.Label>
        <Form.Control
          type="text"
          maxLength="40"
          name="name"
          className="payment-form-field"
          placeholder="Enter cardholder name"
          value={cardHolderName}
          onChange={(e) => {
            setCardHolderName(e.target.value);
          }}
        />
      </Form.Group> */}

      <div className="setup-default-add-card" id="setup-default-add-card">
        <h4>Don’t save my card info</h4>
        <Form.Check type={"radio"} id={`check-api-${"radio"}`}>
          <Form.Check.Input
            type={"radio"}
            id="address-3"
            name="address-3"
            className="new-payment-radio-pay"
            checked={isdontSaveCard === 1 ? true : false}
            // onClick={(e) => {
            //   setIsdontSaveCard(isdontSaveCard === 1 ? 0 : 1);
            // }}
            onClick={handledontsaveCard}
          />
        </Form.Check>
      </div>

      {(isLoading || isInitializing) && (
        <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
          <img
            src={require("../../assets/images/Veuit-Spinner.gif")}
            style={{
              textAlign: "center",
              display: "block",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
            className="c-logo"
            alt="streamit"
          />
        </Backdrop>
      )}

      {!isInitializing && (
        <>
          {loadErrorMessage == null ? (
            <>
              <button
                className="stripeButton"
                disabled={isLoading || !stripe || !elements}
                id="submit"
              >
                <span id="button-text">
                  {isLoading ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    "Complete Order"
                  )}
                </span>
              </button>

              {message && <div id="payment-message">{message}</div>}
            </>
          ) : (
            <div id="payment-message">{loadErrorMessage}</div>
          )}
        </>
      )}
    </form>
  );
}
