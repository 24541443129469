import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./stripe-checkout-form";
import "./stripe-payment.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

export class StripeInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      clientSecret: this.props.clientSecret,
      clientPublicKey: this.props.clientPublicKey,
      orderId: this.props.orderId,
      orderTotalAmount: this.props.orderTotalAmount,
      returnUrl: this.props.returnUrl,
      paymentIntentId: this.props.paymentIntentId,
    };
    this.stripePromise = loadStripe(this.props.clientPublicKey);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const appearance = {
      theme: "stripe",
      variables: {
        colorTextSecondary: '#bdbdbd', 
        colorTextLabel: '#a0a4b1', 
      },
      rules: {
        '.Label': {
          color: 'white',
        },
      },
    };
    const options = {
      clientSecret: this.props.clientSecret,
      appearance,
    };

    return (
      <>
        {this.props.clientSecret && (
          <Elements options={options} stripe={this.stripePromise}>
            <CheckoutForm
              clientSecret={this.state.clientSecret}
              clientPublicKey={this.state.clientPublicKey}
              orderId={this.state.orderId}
              returnUrl={this.state.returnUrl}
              orderTotalAmount={this.state.orderTotalAmount}
              paymentIntentId={this.state.paymentIntentId}
            />
          </Elements>
        )}
      </>
    );
  }
}

export default StripeInfo;
