import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { bindActionCreators } from "redux";
import 'react-notifications/lib/notifications.css';
//import privacyImage from '../../assets/images/privacy_policy.png'


const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})

export class PrivacyPolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: 0,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="iq-main-slider p-0 iq-rtl-direction" style={{position:'inherit'}}>
                {/* <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{ backgroundImage: `url(${privacyImage})`, height: '50vh'  }}>
                    <Container fluid>
                        <Row className="align-items-center">
                            <Col sm="12">
                                <nav aria-label="breadcrumb" className="text-center iq-breadcrumb-two">
                                    <h2 className="title">Privacy Policy</h2>
                                </nav>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
                <main id="main" className="site-main">
                    <Container id="set-privacy-policy">
                        <Row>
                            <Col lg="12" sm="12" className='p-0'>
                                <div className="iq-privacy-policy">
                                    <div className='privacy-text-sec'>
                                        <p>Privacy Policy</p>
                                    </div>
                                    <div className="iq-privacy-policy1">
                                        <div className="contact-privacy">
                                            <p><span className='add-info'>Contact us</span> with any questions or concerns regarding our Privacy Policy.</p>
                                        </div>
                                        <div className='set-bottom-privacy'>
                                            <div className="privacy-text-set">
                                                {/*<h4 className="mb-4 contacting-us">VEUIT Privacy Statement</h4>*/}
                                                <p><span>This Privacy Statement</span> explains our practices, including your choices, regarding the collection, use, and disclosure of certain information, including your personal information in connection with the VEUIT service. To see our California Consumer Privacy Act (CCPA) Privacy Notice, please click on the link, go to</p>
                                                <p>help.VEUIT.com/legal/privacy#ccpa or simply scroll down</p>
                                            </div>
                                            <div className="privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Contacting Us</h4>
                                                <p className="my-3">If you have general questions about your account or how to contact customer service for assistance, please visit our online help center at help.VEUIT.com. For questions specifically about this Privacy Statement, or our use of your personal information, cookies or similar technologies, please contact our Data Protection Officer/Privacy Office by email at privacy@VEUIT.com.</p>
                                                <p className="my-3">The data controller of your personal information is VEUIT, Inc. Please note that if you contact us to assist you, for your safety and ours we may need to authenticate your identity before fulfilling your request.</p>
                                            </div>

                                            <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Collection of Information</h4>
                                                <p className="my-3">We receive and store information about you such as:</p>

                                                <ul className="mb-3 list-contact-set">
                                                    <li><b>Information you provide to us:</b> We collect information you provide to us which includes:</li>

                                                     <ul className="mb-3 list-contact-set">
                                                        <li> your name, email address, address or postal code, payment method(s), telephone number, and other identifiers you might use (such as an in-game name). We collect this information in a number of ways, including when you enter it while using our service, interact with our customer service, or participate in surveys or marketing promotions;</li>
                                                        <li>information when you choose to provide ratings, taste preferences, account settings (including preferences set in the “Account” section of our website), or otherwise provide information to us through our service or elsewhere.</li>
                                                    </ul>

                                                    <li><b>Information we collect automatically: </b>  We collect information about you and your use of our service, your interactions with us and our advertising, as well as information regarding your network, network devices, and your computer or other VEUIT capable devices you might use to access our service (such as gaming systems, smart TVs, mobile devices, set top boxes, and other streaming media devices). This information includes:</li>
                                                     <ul className="mb-3 list-contact-set">
                                                        <li>your activity on the VEUIT service, such as title selections, shows you have watched, search queries, and activity in VEUIT games; </li>
                                                        <li>your interactions with our emails and texts, and with our messages through push and online messaging channels; </li>
                                                        <li>details of your interactions with our customer service, such as the date, time and reason for contacting us, transcripts of any chat conversations, and if you call us, your phone number and call recordings; </li>
                                                        <li>device IDs or other unique identifiers, including for your network devices, and devices that are VEUIT capable on your Wi-Fi network;  </li>
                                                        <li>resettable device identifiers (also known as advertising identifiers), such as those on mobile devices, tablets, and streaming media devices that include such identifiers (see the “Cookies and Internet Advertising” section below for more information); </li>
                                                        <li>device and software characteristics (such as type and configuration), connection information including type (wifi, cellular), statistics on page views, referring source (for example, referral URLs), IP address (which may tell us your general location), browser and standard web server log information; </li>
                                                        <li>information collected via the use of cookies, web beacons and other technologies, including ad data (such as information on the availability and delivery of ads, the site URL, as well as the date and time). (See our “Cookies and Internet Advertising” section for more details.) </li>
                                                    </ul>


                                                    <li><b>Information from partners:  </b>We collect information from other companies with whom you have a relationship (“Partners”). These Partners might include (depending on what services you use): your TV or internet service provider, or other streaming media device providers who make our service available on their device; mobile phone carriers or other companies who provide services to you and collect payment for the VEUIT service for distribution to us or provide pre-paid promotions for the VEUIT service; and voice assistant platform providers who enable interaction with our service through voice commands. The information Partners provide us varies depending on the nature of the Partner services, and may include:</li>
                                                     <ul className="mb-3 list-contact-set">
                                                        <li>search queries and commands applicable to VEUIT that you make through Partner devices or voice assistant platforms; </li>
                                                        <li>service activation information such as your email address or other contact information; </li>
                                                        <li>IP addresses, device IDs or other unique identifiers, as well as associated pre-paid promotion, billing and user interface information, that support user authentication, the VEUIT service registration experience, Partner payment processing, and the presentation of VEUIT content to you through portions of the Partner user interface. </li>
                                                    </ul>

                                                    <li><b>Information from other sources: </b> We also obtain information from other sources. We protect this information according to the practices described in this Privacy Statement, plus any additional restrictions imposed by the source of the data. These sources vary over time, but could include:</li>
                                                     <ul className="mb-3 list-contact-set">
                                                        <li>service providers that help us determine a location based on your IP address in order to customize our service and for other uses consistent with this Privacy Statement; </li>
                                                        <li>security service providers who provide us with information to secure our systems, prevent fraud and help us protect the security of VEUIT accounts; </li>
                                                        <li>payment service providers who provide us with payment or balance information, or updates to that information, based on their relationship with you; </li>
                                                        <li>online and offline data providers, from which we obtain aggregated demographic, interest based and online advertising related data; </li>
                                                        <li>publicly-available sources such as publicly available posts on social media platforms and information available through public databases associating IP addresses with internet service providers (ISPs); </li>
                                                        <li>third party services that you are signed into and that provide functions within VEUIT games, such as multiplayer gameplay, leaderboards, and game saving options. </li>
                                                    </ul>
                                                </ul>
                                            </div>

                                             <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Use of Information</h4>
                                                <p className="my-3">We use information to provide, analyze, administer, enhance and personalize our services and marketing efforts, to manage member referrals, to process your registration, your orders and your payments, and to communicate with you on these and other topics. For example, we use such information to:</p>

                                                <ul className="mb-3 list-contact-set">
                                                    <li> determine your general geographic location, provide localized content, provide you with customized and personalized viewing recommendations for movies and TV shows we think will be of interest to you, determine your ISP to support network troubleshooting for you (we also use aggregated ISP information for operational and business purposes), and help us quickly and efficiently respond to inquiries and requests;</li>
                                                    <li>coordinate with Partners on making the VEUIT service available to members and providing information to non members about the availability of the VEUIT service, based on the specific relationship you have with the Partner;</li>
                                                    <li>secure our systems, prevent fraud and help us protect the security of VEUIT accounts;</li>
                                                    <li>prevent, detect and investigate potentially prohibited or illegal activities, including fraud, and to enforce our terms (such as determining whether and for which VEUIT signup offers you are eligible and determining whether a particular device is permitted to use the account consistent with our Terms of Use); </li>
                                                    <li>analyze and understand our audience, improve our service (including our user interface experiences and service performance) and optimize content selection, recommendation algorithms and delivery;</li>
                                                    <li>communicate with you concerning our service so that we can send you news about VEUIT, details about new features and content available on VEUIT, special offers, promotional announcements, consumer surveys, and to assist you with operational requests such as password reset requests. These communications may be by various methods, such as email, push notifications, text message, online messaging channels, and matched identifier communications (described below). Please see the “Your Choices” section of this Privacy Statement to learn how to set or change your communications preferences.</li>
                                                    <p className="my-3">Our recommendations system strives to predict what you will be in the mood to watch when you log in. However, our recommendations system does not infer or attach socio-demographic information (like gender or race) to you. We have a Help article that explains how our recommendations system works – spoilers: the biggest driver of recommendations is what you actually choose to watch through our service. You can read the article at www.VEUIT.com/recommendations.</p>
                                                    <li><b>Disclosure of Information</b> We disclose your information for certain purposes and to third parties, as described below:</li>
                                                     <ul className="mb-3 list-contact-set">
                                                        <li><b>The VEUIT family of companies: </b> We share your information among the VEUIT family of companies (help.VEUIT.com/legal/corpinfo) as needed for: providing you with access to our services; providing customer support; making decisions about service improvements; content development; and for other purposes described in the Use of Information section of this Privacy Statement.</li>
                                                        <li><b>Service Providers:</b>  We use other companies, agents or contractors (“Service Providers”) to perform services on our behalf or to assist us with the provision of services to you. For example, we engage Service Providers to provide marketing, advertising, communications, security, infrastructure and IT services, to customize, personalize and optimize our service, to provide bank account or balance information, to process credit card transactions or other payment methods, to provide customer service, to analyze and enhance data (including data about users’ interactions with our service), and to process and administer consumer surveys. In the course of providing such services, these Service Providers may have access to your personal or other information. We do not authorize them to use or disclose your personal information except in connection with providing their services (which includes maintaining and improving their services).</li>
                                                        <li><b>Partners:</b>  As described above, you may have a relationship with one or more of our Partners, in which case we may share certain information with them in order to coordinate with them on providing the VEUIT service to members and providing information about the availability of the VEUIT service. For example, depending on what Partner services you use, we may share information:</li>
                                                         <ul className="mb-3 list-contact-set">
                                                            <li> in order to facilitate Partner pre-paid promotions or collection of payment for the VEUIT service for distribution to us;</li>
                                                            <li> with Partners who operate voice assistant platforms that allow you to interact with our service using voice commands;</li>
                                                            <li> so that content and features available in the VEUIT service can be suggested to you in the Partner’s user interface. For members, these suggestions are part of the VEUIT service and may include customized and personalized viewing recommendations.</li>
                                                        </ul>
                                                        <li><b>Promotional offers:</b> We may offer joint promotions or programs that, in order for your participation, will require us to share your information with third parties. In fulfilling these types of promotions, we may share your name and other information in connection with fulfilling the incentive. Please note that these third parties are responsible for their own privacy practices. </li>
                                                        <li><b>Protection of VEUIT and others:</b> VEUIT and its Service Providers may disclose and otherwise use your personal and other information where we or they reasonably believe such disclosure is needed to (a) satisfy any applicable law, regulation, legal process, or governmental request, (b) enforce applicable terms of use, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address illegal or suspected illegal activities (including payment fraud), security or technical issues, or (d) protect against harm to the rights, property or safety of VEUIT, its users or the public, as required or permitted by law. </li>
                                                        <li><b>Business transfers:</b>  In connection with any reorganization, restructuring, merger or sale, or other transfer of assets, we will transfer information, including personal information, provided that the receiving party agrees to respect your personal information in a manner that is consistent with our Privacy Statement.</li>
                                                    </ul>
                                                    <p className="my-3"> Whenever in the course of sharing information we transfer personal information to other countries, we will ensure that the information is transferred in accordance with this Privacy Statement and as permitted by the applicable laws on data protection.</p>
                                                    <p className="my-3">You may also choose to disclose your information in the following ways: </p>

                                                     <ul className="mb-3 list-contact-set">
                                                        <li>certain portions of our service may contain a tool which gives you the option to share information by email, text message and social or other sharing applications, using the clients and applications on your smart device; </li>
                                                        <li>social plugins and similar technologies allow you to share information; and </li>
                                                        <li>through VEUIT game features, such as multiplayer options and leaderboards. </li>
                                                    </ul>
                                                    <p className="my-3">  Social plugins and social applications are operated by the social networks themselves, and are subject to their terms of use and privacy policies. Similarly, some of the VEUIT game features mentioned above (such as multiplayer gameplay, leaderboards, and game saving options) may require use of a third-party service, which is subject to that service’s terms of use and privacy policy.</p>

                                                </ul>
                                            </div>

                                             <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Access to Account and Profiles</h4>
                                                <ul className="mb-3 list-contact-set">
                                                    <li><b>“Remember me” function: </b> For ease of access to your account, you can use the “Remember me on this device” function when you log in to the website. This function uses technology that enables us to provide direct account access and to help administer the VEUIT service without requiring reentry of any password or other user identification when your browser revisits the service.</li>
                                                    <li><b>Giving others access to your account: </b> If you share or otherwise allow others to have access to your account, they will be able to see shows you have watched, ratings, account information (including your email address or other information in the “Account” area of our website), and game related information (such as your in-game name and saved game progress). This remains true even if you use our profiles feature. You might have the option to use seamless account login through the VEUIT mobile app, which enables login to the VEUIT app on smart TVs, set top boxes, and other streaming media devices on the Wi-Fi network to which you are connected. If you use that feature, those devices will remain signed into your account unless you later log out of those devices.</li>
                                                    <li><b>Profiles: </b> Profiles allow users to have a distinct, personalized VEUIT experience, built around the movies and shows of interest to them, as well as separate watch histories. Please note that profiles are available to everyone who uses your VEUIT account, so that anyone with access to your VEUIT account can navigate to and use, edit or delete profiles. You should explain this to others with access to your account, and if you do not wish them to use or change your profile, be sure to let them know. Profile users may be offered the opportunity to add an email address, phone number or other information to the profile and will be provided with notice of collection and use at the time such information is requested (the Profile Privacy Notice applies (help.VEUIT.com/legal/profileprivacy)). We have various parental controls available, you can learn more by reading our Help article located at https://help.VEUIT.com/node/264.</li>
                                                    <li><b>Removing device access to your VEUIT account: </b> To remove access to your VEUIT account from your devices, visit the “Account” section of our website, choose “Sign out of all devices,” and follow the instructions to deactivate your devices (note, deactivation may not occur immediately). Where possible, users of public or shared devices should log out at the completion of each visit. If you sell or return a computer or VEUIT ready device, you should log out and deactivate the device before doing so. If you do not maintain the security of your password or device, or fail to log out or deactivate your device, subsequent users may be able to access your account, including your personal information.</li>
                                                </ul>
                                            </div>
                                            <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Your Choices</h4>
                                                <p className="my-3"><b>Email and Text Messages.</b> If you no longer want to receive certain communications from us via email or text message, simply access the “Communications Settings” option in the “Account” section of our website and uncheck those items to unsubscribe. Alternatively, click the “unsubscribe” link in the email or reply STOP (or as otherwise instructed) to the text message. Please note that you cannot unsubscribe from service-related correspondence from us, such as messages relating to your account transactions.</p>
                                                <p className="my-3"><b>Push Notifications.</b> You can choose to receive mobile push notifications from VEUIT. On some device operating systems, you will be automatically enrolled in the notifications. If you subsequently decide you no longer wish to receive these notifications, you can use your mobile device’s settings functionality to turn them off. We also offer push notifications on certain web browsers. If you agree to receive those notifications and subsequently decide you no longer wish to receive these notifications, you can use your browser’s settings to turn them off.</p>
                                                <p className="my-3"><b>WhatsApp.</b> If you have enabled WhatsApp messages in connection with your VEUIT account or profile and no longer wish to receive them, you can use your WhatsApp app settings to block them.</p>
                                                <p className="my-3"><b>Interest-Based Ads.</b> Interest-based ads are online ads that are tailored to your likely interests based on your use of various apps and websites across the Internet. If you are using a browser, then cookies and web beacons can be used to collect information to help determine your likely interests. If you are using a mobile device, tablet, or streaming media device that includes a resettable device identifier, then that identifier can be used to help determine your likely interests. For your choices about interest-based ads from VEUIT, please see the “Cookies and Internet Advertising” section (below).</p>
                                                <p className="my-3"><b>Matched Identifier Communications. </b> Some third party sites and apps allow us to reach our users with online promotions about our titles and services by sending a privacy protective identifier to the third party. A privacy protective identifier means we convert the original information (such as an email address or phone number) into a value to keep the original information from being revealed. The third party compares the privacy protective identifier to identifiers in its database and there will be a match only if you have used the same identifier (such as an email address) with VEUIT and the third party. If there is a match, VEUIT can then choose whether or not to send a given promotional communication to you on that third party site or app, and can optimize and better measure the effectiveness of online advertising. You can opt out in the “Marketing Communications” section of the “Account” section of our website.</p>
                                            </div>
                                            <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Your Information and Rights</h4>
                                                <p className="my-3">You can request access to your personal information, or correct or update out-of-date or inaccurate personal information we hold about you. You may also request that we delete personal information that we hold about you.</p>
                                                <p className="my-3">When you visit the “Account” portion of our website, you have the ability to access and update a broad range of information about your account, including your contact information, your VEUIT payment information, and various related information about your account (such as the content you have viewed and rated). You must be signed in to access the “Account” section.</p>
                                                <p className="my-3">If you are the account owner, to download a copy of your personal information go to: www.VEUIT.com/account/getmyinfo (you must be signed in to access the “Account” section), and follow the instructions.</p>
                                                <p className="my-3">For other requests, or if you have any other question regarding our privacy practices, please contact our Data Protection Officer/Privacy Office at privacy@VEUIT.com. For more information about how to access your information, including our verification process, please reference this help article: help.VEUIT.com/node/100624. For information about deletion, removal and retention of information, please reference this help article: help.VEUIT.com/node/100625. We</p>
                                                <p className="my-3"> respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. Please also see the “Your Choices” section of this Privacy Statement for additional choices regarding your information.</p>
                                                <p className="my-3">We may reject requests that are unreasonable or not required by law, including those that would be extremely impractical, could require disproportionate technical effort, or could expose us to operational risks such as free trial fraud. We may retain information as required or permitted by applicable laws and regulations, including to honor your choices, for our billing or records purposes and to fulfill the purposes described in this Privacy Statement. We take reasonable measures to destroy or de-identify personal information in a secure manner when it is no longer required.</p>
                                                <p className="my-3">If you are a California consumer, please see our California Consumer Privacy Act (CCPA) Privacy Notice by clicking on the link, going to help.VEUIT.com/legal/privacy#ccpa or simply scrolling down.</p>
                                            </div>

                                            <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Security</h4>
                                                <p className="my-3">We use reasonable administrative, logical, physical and managerial measures to safeguard your personal information against loss, theft and unauthorized access, use and modification. These measures are designed to provide a level of security appropriate to the risks of processing your personal information. Unfortunately, no measures can be guaranteed to provide 100% security. Accordingly, we cannot guarantee the security of your personal information.</p>
                                            </div>

                                            <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Other Websites, Platforms and Applications</h4>
                                                <p className="my-3">The VEUIT service may be provided through and/or utilize features (such as voice controls) operated by third party platforms, or contain links to sites operated by third parties whose policies regarding the handling of information may differ from ours. For example, you may be able to access the VEUIT service through platforms such as gaming systems, smart TVs, mobile devices, set top boxes and a number of other Internet connected devices. These websites and platforms have separate and independent privacy or data policies, privacy statements, notices and terms of use, which we recommend you read carefully. In addition, you may encounter third party applications that interact with the VEUIT service.</p>
                                            </div>

                                            <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Children</h4>
                                                <p className="my-3">You must be at least 18 years of age or older to subscribe to the VEUIT service. Minors may only use the service with the involvement, supervision, and approval of a parent or legal guardian.</p>
                                            </div>


                                            <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Changes to This Privacy Statement</h4>
                                                <p className="my-3">We will update this Privacy Statement from time to time in response to changing legal, regulatory or operational requirements. We will provide notice of any such changes (including when they will take effect) in accordance with law. Your continued use of the VEUIT service after any such updates take effect will constitute acknowledgement and (as applicable) acceptance of those changes. If you do not wish to acknowledge or accept any updates to this Privacy Statement, you may cancel your use of the VEUIT service. To see when this Privacy Statement was last updated, please see the “Last Updated” section below.</p>
                                            </div>

                                             <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">Cookies and Internet Advertising</h4>
                                                <p className="my-3">We and our Service Providers use cookies and other technologies (such as web beacons), as well as resettable device identifiers, for various reasons. We want you to be informed about our use of these technologies, so this section explains the types of technologies we use, what they do and your choices regarding their use.</p>
                                                <p className="my-3"><b>Cookies and similar technologies, web beacons, and resettable device identifiers</b></p>
                                                <p className="my-3">Cookies are small data files that are commonly stored on your device when you browse and use websites and online services. We use other technologies such as browser storage and plugins (e.g., HTML5, IndexedDB, and WebSQL). Like cookies, these other technologies may store small amounts of data on your device. Web beacons (also known as clear gifs or pixel tags) often work in conjunction with cookies. In many cases, declining cookies will impair the effectiveness of web beacons associated with those cookies.</p>
                                                <p className="my-3">If you use the VEUIT app on a mobile device, tablet, or streaming media device, we may collect a resettable device identifier from your device. Resettable device identifiers (also known as advertising identifiers) are similar to cookies and are found on many mobile devices and tablets (for example, the “Identifier for Advertisers” (or IDFA) on Apple iOS devices and the “Google Advertising ID” on Android devices), and certain streaming media devices. Like cookies, resettable device identifiers  are used to make online advertising more relevant and for analytics and optimization purposes.</p>
                                                <p className="my-3"><b>Why does VEUIT use these technologies?</b></p>
                                                <p className="my-3">We use these types of technologies for various reasons, like making it easy to access our services by remembering you when you return; to provide, analyze, understand and enhance the use of our services; to enforce our terms, prevent fraud; to improve site performance, monitor visitor traffic and actions on our site; and to deliver and tailor our marketing or advertising, and to understand interactions with our emails, marketing, and online ads on third party sites.</p>
                                                <p className="my-3">To help you better understand how we use cookies and resettable device identifiers, please see the information below:</p>
                                                 <ul className="mb-3 list-contact-set">
                                                    <li><b>Essential cookies:</b>  These cookies are strictly necessary to provide our website or online service. For example, we and our Service Providers may use these cookies to authenticate and identify our members when they use our websites and applications so we can provide our service to them. They also help us to enforce our Terms of Use, prevent fraud and maintain the security of our service.</li>
                                                    <li><b>Performance and functionality cookies:</b> These cookies help us to customize and enhance your online experience with VEUIT. For example, they help us to remember your preferences and prevent you from needing to re-enter information you previously provided (for example, during member sign up). We also use these cookies to collect information (such as popular pages, conversion rates, viewing patterns, click-through and other information) about our visitors’ use of the VEUIT service so that we can enhance and personalize our website and service and conduct market research. Deletion of these types of cookies may result in limited functionality of our service. </li>
                                                    <li><b>Advertising cookies and resettable device identifiers:</b> These cookies and resettable device identifiers use information about your use of this and other websites and apps, your response to ads and emails, and to deliver ads that are more relevant to you. These types of ads are called “interest-based advertising.” The advertising cookies associated with our service belong to our Service Providers. </li>
                                                </ul>
                                                <p className="my-3">In connection with our use of these technologies, some of the websites and apps where we advertise, as well as advertising technology companies that we use to purchase, deliver, optimize, and/or measure our advertisements (collectively “Advertising Partners”), may receive limited information from us as part of our campaign targeting, measurement, and optimization (e.g., steps completed in sign-up and site visit or app open/install information).</p>
                                                <p className="my-3">Common uses of this type of information are to judge the effectiveness of and optimize ad campaigns, by allowing Advertising Partners to see when someone who saw an ad later signed up for our service.  Another common use is to make sure we do not serve ads trying to get individuals to sign up for the VEUIT service if they are already a VEUIT user.  </p>
                                                <p className="my-3">VEUIT uses contractual and technical measures designed to prevent Advertising Partners from accessing information regarding specific title selections you make, URLs you land on, or shows you have watched on our service. We do not share information about title selections or your shows you have watched on our service. </p>
                                                <p className="my-3"><b>How can I exercise choice regarding these technologies?</b></p>

                                                 <ul className="mb-3 list-contact-set">
                                                    <li><b>To exercise choice regarding cookies </b> <br />For more information about cookies set through our website, as well as other types of online tracking (including the collection of information by third parties about your online activities over time and across third-party websites or online services for online interest based advertising), and to exercise choices regarding them, click here. At this time, we do not respond to web browser “do not track” signals.</li>
                                                    <li><b>To exercise choice regarding resettable device identifiers </b> <br />To opt out of interest-based ads from VEUIT in connection with an resettable device identifier on a mobile device, tablet, or streaming media devices, please configure the appropriate setting on your device (usually found under “privacy” or “ads” in your device’s settings). You may still see VEUIT ads on your device, but they will not be tailored to your likely interests.</li>
                                                    <li><b>To exercise choice using self-regulatory program resources </b> <br />VEUIT supports the following self-regulatory programs, which provide additional privacy choices for interest-based advertising:</li>
                                                     <ul className="mb-3 list-contact-set">
                                                        <li> In the US: <Link to="https://youradchoices.com/" style={{ color: '#2DD0FF' }}>Digital Advertising Alliance (DAA)</Link></li>
                                                    </ul>
                                                    <li><b>To exercise choice regarding other technologies</b><br />There are a number of ways to exercise choice regarding technologies that are similar to cookies, such as browser storage and plugins (e.g., HTML5, IndexedDB, and WebSQL). For example, many popular browsers provide the ability to clear browser storage, commonly in the settings or preferences area; see your browser’s help function or support area to learn more. Other technologies, such as Silverlight storage, may be cleared from within the application.</li>
                                                </ul>
                                            </div>

                                             <div className="mb-3 privacy-text-set">
                                                <h4 className="mb-4 contacting-us">California Consumer Privacy Act (CCPA) Privacy Notice</h4>
                                                <p className="my-3">This Privacy Notice applies to California consumers and supplements the Privacy Statement.</p>
                                                <p className="my-3">Personal Information We Collect</p>
                                                <p className="my-3">We collect information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household (“CCPA personal information”). We have collected the following categories of CCPA personal information from consumers within the last twelve (12) months:</p>
                                                 <ul className="mb-3 list-contact-set">
                                                    <li><b>Identifiers</b>: “Identifiers” covers a broad range of information, including things like name, email address, postal address, telephone number, and IP address. We collect some CCPA personal information that falls into this category. For example, for streaming subscribers we’re going to have an email address, payment information (like a credit card), and for DVD customers we’re going to have a postal address. We collect IP addresses, identifiers from the devices you use to connect, and characteristics about the networks you use when you connect to our service online. These sorts of identifiers are connected to your VEUIT account. There are also identifiers we use for purposes like advertising (resettable device identifiers, third party advertising cookies on our website) that we do not associate with your account, but that can be used to reach you with promotional messages on other websites and applications. </li>
                                                    <li><b>Characteristics of protected classifications under California or federal law</b>: This covers a broad range of information like race, sex, marital status, among other protected classes. We will collect this sort of information about you if you choose to participate in our optional research surveys, or other research activity such as an in person interview or panel. We do not collect this information when you register for the VEUIT service.  </li>
                                                    <li><b>Commercial information</b>: This includes information about products or services considered, obtained or purchased. </li>
                                                    <li><b>Internet or other electronic network activity information</b>:  We offer online services, so we collect CCPA personal information in this category, like your interactions with our service. </li>
                                                    <li><b>Geolocation data</b> : Our online services don’t collect precise geolocation data from GPS sensors. However, we do collect IP addresses from which we can determine your general location.</li>
                                                    <li><b>Audio, electronic, visual, thermal, olfactory or similar information</b> : We may collect photos, audio and/or video of you in certain circumstances. For example if you contact customer service, we may record the call (voice recording).</li>
                                                    <li><b>Inferences</b>: We have a recommendations system that strives to predict what you’ll be in the mood to watch when you log in. However, we do not have a list in our recommendation systems that attaches socio-demographic information to you like “soccer dad who likes rom coms” or “financially savvy mom who craves action films” or “singleton high school grad who likes tiny houses.” We have a Help article that explains how our recommendations system works – spoilers: the biggest driver is what you actually choose to watch through our service. You can read the article at www.VEUIT.com/recommendations. </li>
                                                </ul>
                                                <p className="my-3">Uses of CCPA personal information</p>
                                                <p className="my-3">We use categories of CCPA personal information listed above for the purposes noted in the Use of Information section of our Privacy Statement. For ease of reference, we repeat that section below:</p>
                                                <p className="my-3">We use personal information to provide, analyze, administer, enhance and personalize our services and marketing efforts, to process your registration, your orders and your payments, and to communicate with you on these and other topics. For example, we use such information to: </p>
                                                 <ul className="mb-3 list-contact-set">
                                                    <li>determine your general geographic location, provide localized content, provide you with customized and personalized viewing recommendations for movies and TV shows we think will be of interest to you, determine your Internet service provider, and help us quickly and efficiently respond to inquiries and requests; </li>
                                                    <li>to coordinate with Partners on making the VEUIT service available to members and providing information to non members about the availability of the VEUIT service, based on the specific relationship you have with the Partner; </li>
                                                    <li>prevent, detect and investigate potentially prohibited or illegal activities, including fraud, and to enforce our terms (such as determining free trial eligibility);  </li>
                                                    <li>analyze and understand our audience, improve our service (including our user interface experiences) and optimize content selection, recommendation algorithms and delivery; </li>
                                                    <li>communicate with you concerning our service so that we can send you news about VEUIT, details about new features and content available on VEUIT, special offers, promotional announcements, consumer surveys, and to assist you with operational requests such as password reset requests. These communications may be by various methods, such as email, push notifications, text message, online messaging channels, and matched identifier communications (described below). Please see the “Your Choices” section of this Privacy Statement to learn how to set or change your communications preferences.  </li>
                                                </ul>
                                                <p className="my-3">Categories of CCPA personal information disclosed for a business purpose</p>
                                                <p className="my-3">We disclose the categories of CCPA personal information listed below for business purposes. (Please see the Disclosure of Information section of our Privacy Statement for additional details that may be of interest to you.)</p>

                                                 <ul className="mb-3 list-contact-set">
                                                    <li><b>Identifiers</b>: We may disclose identifiers for business purposes with the following categories of third parties: Service Providers, Partners, an entity engaged in a business transfer, law enforcement, courts, governments and regulatory agencies. </li>
                                                    <li><b>Characteristics of protected classifications under California or federal law</b>: We may disclose these types of characteristics for business purposes with the following categories of third parties: Service Providers, an entity engaged in a business transfer/merger, law enforcement, courts, governments and regulatory agencies. </li>
                                                    <li><b>Commercial information</b>: We may disclose commercial information for business purposes with the following categories of third parties: Service Providers, Partners, an entity engaged in a business transfer/merger, law enforcement, courts, governments and regulatory agencies. </li>
                                                    <li><b>Internet or other electronic network activity information</b>: We may disclose these types of information for business purposes with the following categories of third parties: Service Providers, Partners, an entity engaged in a business transfer/merger, law enforcement, courts, governments and regulatory agencies. </li>
                                                    <li><b>Geolocation data</b>: We may disclose geolocation data for business purposes with the following categories of third parties: Service Providers, Partners, an entity engaged in a business transfer/merger, law enforcement, courts, governments and regulatory agencies. </li>
                                                    <li><b>Audio, electronic, visual, thermal, olfactory or similar information</b>: We may disclose these types of information for business purposes with the following categories of third parties: Service Providers, Partners, an entity engaged in a business transfer/merger, law enforcement, courts, governments and regulatory agencies. </li>
                                                    <li><b>Inferences</b>: We may disclose these types of data for business purposes with the following categories of third parties: an entity engaged in a business transfer/merger. </li>
                                                </ul>

                                                <p className="my-3">Sources of CCPA personal information</p>
                                                <p className="my-3">We explain our sources of information in the Collection of Information section of our Privacy Statement. (Please see that section for more information that may be of interest to you.) For ease of reference, in regard to CCPA personal information these are:</p>

                                                 <ul className="mb-3 list-contact-set">
                                                    <li>You (the consumer) when you provide us CCPA personal information directly, and when we collect it automatically based on your interactions with us (for example, through your computer or other VEUIT capable device you use to access our service (such as gaming systems, smart TVs, mobile devices, set top boxes, and other streaming media devices); </li>
                                                    <li>Partners (other companies with whom you have a relationship). These Partners might include (depending on what services you use): your TV or Internet service provider, or other streaming media device providers who make our service available on their device; mobile phone carriers or other companies who provide services to you and collect payment for the VEUIT service for distribution to us; voice assistant platform providers who enable interaction with our service via voice commands;  third party services that you are signed into that provide functions within VEUIT games, such as multiplayer gameplay, leaderboards, and game saving options; and </li>
                                                    <li>Other sources: service providers who support our business including security and payment service providers, and publicly-available sources, specifically, public posts on social media platforms; information about Internet Service Providers (ISPs) used by members to access VEUIT through public databases associating IP addresses with ISPs. </li>

                                                </ul>
                                                <p className="my-3">Your rights under the CCPA</p>

                                                 <ul className="mb-3 list-contact-set">
                                                    <li>You have the right to request that we disclose: what categories and specific pieces of CCPA personal information have been collected about you; the categories of sources from which CCPA personal information are collected; our business or commercial purpose for collecting, using, or disclosing CCPA personal information; the categories of third parties with whom we share CCPA personal information; the categories of CCPA personal information we have disclosed about you for a business purpose. We do not sell personal information. </li>
                                                    <li>You have a right to receive a copy of the specific CCPA personal information we have collected about you. </li>
                                                    <li>You have a right to deletion of your CCPA personal information, subject to exceptions under the CCPA. </li>
                                                    <li>You have a right not to receive discriminatory treatment for exercising any of your CCPA rights. We will not discriminate against you based on your exercise of any of your CCPA rights.  </li>
                                                </ul>

                                                <p className="my-3">You can assert these rights only where we receive a verified request from you. For information on how to exercise your rights, please see the Your Information and Rights section above.</p>
                                                <p className="my-3">If you are a consumer under the CCPA and wish to contact us through an authorized agent, the authorized agent can submit a request on your behalf at privacy@VEUIT.com along with a statement signed by you certifying that the agent is authorized to act on your behalf. In order to verify the request and your identity, we may ask you to verify your identity. For more information about how to access your information, including our verification process, please reference this help article help.VEUIT.com/node/100624.</p>
                                                <p className="my-3">If you do not own a VEUIT account, we may not be able to respond to requests to exercise rights under CCPA, including the right to know or delete CCPA personal information. Because we only collect limited information about individuals without an account, we are unable to verify requests from non-account holders to the standard required by the CCPA.</p>
                                                <p className="my-3">To see the prior version of this document, please go to www.VEUIT.com/privacyupdates.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy)