import { createSelector } from "reselect";

const selectData = (state) => state.home;

export const selectHomeDataStatus = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    syncinghome: home.syncinghome,
    bannerList: home.bannerList,
    message: home.message,
  };
});

export const selectTabList = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    tabList: home.tabList,
    message: home.message,
  };
});

export const selectNewRelease = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    newReleaseList: home.newReleaseList,
    message: home.message,
  };
});

export const selectClassic = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    classicList: home.classicList,
    message: home.message,
  };
});

export const selectIndependent = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    independentList: home.independentList,
    message: home.message,
  };
});

export const selectOriginalSeries = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    originalSeriesList: home.originalSeriesList,
    message: home.message,
  };
});

export const selectInfluencer = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    influencerList: home.influencerList,
    message: home.message,
  };
});

export const selectComedy = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    comedyList: home.comedyList,
    message: home.message,
  };
});

export const selectMusic = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    musicList: home.musicList,
    message: home.message,
  };
});

export const selectBrands = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    brandsList: home.brandsList,
    message: home.message,
  };
});

export const selectBrandsPartners = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    brandsPartnersList: home.brandsPartnersList,
    message: home.message,
  };
});

export const selectExclusiveEvent = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    exclusiveeventList: home.exclusiveeventList,
    message: home.message,
  };
});

export const selectFeaturedChannels = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    featuredChannelsList: home.featuredChannelsList,
    message: home.message,
  };
});

export const selectTvChannels = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    tvChannelsList: home.tvChannelsList,
    message: home.message,
  };
});

export const selectPodcast = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    podcastList: home.podcastList,
    message: home.message,
  };
});

export const selectTrendingLives = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    trendinglivesList: home.trendinglivesList,
    message: home.message,
  };
});

export const selectAthletes = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    athletesList: home.athletesList,
    message: home.message,
  };
});

export const selectFineArtists = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    fineArtistsList: home.fineArtistsList,
    message: home.message,
  };
});

export const selectChannelCategory = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    channelCategoryList: home.channelCategoryList,
    message: home.message,
  };
});

export const selectLocation = createSelector([selectData], (home) => {
  return { location: home.location };
});

export const selectEmployeeData = createSelector([selectData], (home) => {
  return home.employeeData;
});

export const selectSigninRedirectPath = createSelector([selectData], (home) => {
  return home.signinRedirectPath;
});

export const selectFeatured = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    featuredList: home.featuredList,
    message: home.message,
  };
});
