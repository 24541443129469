import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserService from "../../services/user/user.service";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import AppConfig from "../../config/app.config";
import Backdrop from "@material-ui/core/Backdrop";
import validator from "validator";
import { selectEmployeeData } from "../../store/home/home.selectors";
import { setSigninRedirectPath } from "../../store/home/home.actions";

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setSigninRedirectPath }, dispatch),
});

export class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      employeeId: "",
      currentPassword: "",
      password: "",
      confirmPassword: "",
      profileImage: "",
      currentPasswordType: "password",
      passwordType: "password",
      rePasswordType: "password",
    };

    this.handleCurrentPassword = this.handleCurrentPassword.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      this.setState({
        employeeId: this.props.employeeData?.employeeId,
        profileImage:
          AppConfig.API.USER_PROFILE +
          this.props.employeeData?.employeeId +
          "&random=" +
          Date.now(),
      });
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      });

      const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL + window.location.pathname);
      const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
    }
  }

  handleCurrentPassword(event) {
    this.setState({ currentPassword: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleConfirmPassword(event) {
    this.setState({ confirmPassword: event.target.value });
  }

  updatePassword = () => {
    if (this.state.currentPassword === "") {
      NotificationManager.warning("Please Enter Current Password.", "", 2000);
    } else if (this.state.password === "") {
      NotificationManager.warning("Please Enter Password.", "", 2000);
    } else if (!validator.isStrongPassword(this.state.password)) {
      NotificationManager.warning(
        "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character and length between 8 to 20 characters.",
        "",
        2000
      );
    } else if (this.state.confirmPassword === "") {
      NotificationManager.warning("Please Enter Re-enter Password.", "", 2000);
    } else if (this.state.confirmPassword !== this.state.password) {
      NotificationManager.warning("Password's do not match.", "", 2000);
    } else {
      const payload = {
        isEmail: 1,
        oldPassword: this.state.currentPassword,
        newPassword: this.state.password,
        userId: this.state.employeeId,
      };

      this.setState({ isLoading: 1 });
      UserService.updatePassword(payload, this.state.employeeId)
        .then((res) => {
          if (res.error != null) {
            this.setState({ isLoading: 0 });
            NotificationManager.error(
              res.error.response.data.message,
              "",
              2000
            );
          } else {
            if (res["status"]) {
              this.setState({
                currentPassword: "",
                password: "",
                confirmPassword: "",
                isLoading: 0,
              });
              NotificationManager.success(
                "Password Updated Successfully.",
                "",
                2000
              );
              this.timeoutHandle = setTimeout(() => {
                localStorage.clear();

                const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL);
                const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
                window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
              }, 3000);
            } else {
              this.setState({ isLoading: 0 });
              NotificationManager.error(res["message"], "", 2000);
            }
          }
        })
        .catch((err) => {
          this.setState({ isLoading: 0 });
          NotificationManager.error(err, "", 2000);
        });
    }
  };

  showCurrentPassword = () => {
    if (this.state.currentPasswordType === "password") {
      this.setState({ currentPasswordType: "text" });
    } else {
      this.setState({ currentPasswordType: "password" });
    }
  };

  showPassword = () => {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  };

  showRePassword = () => {
    if (this.state.rePasswordType === "password") {
      this.setState({ rePasswordType: "text" });
    } else {
      this.setState({ rePasswordType: "password" });
    }
  };

  render() {
    return (
      <section className="m-profile manage-p">
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          ""
        )}
        <Container>
          <Row className="row align-items-center justify-content-center h-100">
            <Col lg="10">
              <div className="sign-user_card">
                <Row>
                  <Col xl="3" lg="3" className="text-center">
                    <div className="upload_profile d-inline-block">
                      <img
                        src={this.state.profileImage}
                        className="profile-pic avatar-130 rounded-circle img-fluid"
                        alt="user"
                      />
                    </div>
                  </Col>
                  <Col xl="9" lg="9" className="device-margin">
                    <div className="profile-from">
                      <h4 className="mb-3 change-pwd-text">Change Password</h4>
                      <Form className="mt-4" action="#">
                        <Form.Group className="form-group">
                          <Form.Label>Current Password</Form.Label>
                          <div className="input-group">
                            <Form.Control
                              type={this.state.currentPasswordType}
                              className="form-control mb-0"
                              id="currentPassword"
                              maxLength="20"
                              placeholder="Current password"
                              value={this.state.currentPassword}
                              onChange={this.handleCurrentPassword}
                              autoComplete="off"
                              required
                            />
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend2"
                                onClick={this.showCurrentPassword}
                                style={{
                                  backgroundColor: "#141414",
                                  border: "1px solid #404043",
                                  color: "#fff",
                                }}
                              >
                                <i
                                  className={
                                    this.state.currentPasswordType ===
                                      "password"
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>New Password</Form.Label>
                          <div className="input-group">
                            <Form.Control
                              type={this.state.passwordType}
                              className="form-control mb-0"
                              id="password"
                              maxLength="20"
                              placeholder="password"
                              value={this.state.password}
                              onChange={this.handlePassword}
                              autoComplete="off"
                              required
                            />
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend3"
                                onClick={this.showPassword}
                                style={{
                                  backgroundColor: "#141414",
                                  border: "1px solid #404043",
                                  color: "#fff",
                                }}
                              >
                                <i
                                  className={
                                    this.state.passwordType === "password"
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>Re-enter Password</Form.Label>
                          <div className="input-group">
                            <Form.Control
                              type={this.state.rePasswordType}
                              className="form-control mb-0"
                              id="confirmPassword"
                              maxLength="20"
                              placeholder="Re-enter password"
                              value={this.state.confirmPassword}
                              onChange={this.handleConfirmPassword}
                              autoComplete="off"
                              required
                            />
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend4"
                                onClick={this.showRePassword}
                                style={{
                                  backgroundColor: "#141414",
                                  border: "1px solid #404043",
                                  color: "#fff",
                                }}
                              >
                                <i
                                  className={
                                    this.state.rePasswordType === "password"
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Form.Group>

                        <div className="d-flex change-pwd-save">
                          <Link
                            to="#"
                            onClick={this.updatePassword}
                            value=""
                            className="btn"
                            id="changepwd-save"
                          >
                            Save
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
