import SignUpActionTypes from './signUp.types';
// Set the reducers initial state.
const INITIAL_STATE = {
    loading: false,
    errorMessage: null,
    signUpSuccess: null,
    employeeData:null,
}

// Define scenarios for reducers.
const signUpReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SignUpActionTypes.SIGNUP_SUCCESS:
            return {
                ...state,
                signUpSuccess: action.payload.signUpSuccess,
                loading: action.payload.loading,
                employeeData:action.payload.employeeData
            };
        case SignUpActionTypes.SIGNUP_FAILURE:
            return {
                ...state,
                signUpSuccess: action.payload.signUpSuccess,
                loading: action.payload.loading,
                errorMessage: action.payload.err,
                employeeData:action.payload.employeeData
            };
        default:
            return state
    }
}

export default signUpReducer;