import { all, call } from "typed-redux-saga/macro"
import { signUpSagas } from "./signUp/signUp.sagas"
import { signInSagas } from "./signIn/signIn.sagas"
import { homeSagas } from "./home/home.sagas"
import { purchaseSaga } from "./PurchaseSeriesList/PurchaseSeriesList.sagas"

export function* rootSaga() {
  yield* all([
    call(signUpSagas),
    call(signInSagas),
    call(homeSagas),
    call(purchaseSaga),
  ])
}
