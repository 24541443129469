import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-notifications/lib/notifications.css";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class MissionPurpose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setfaq = (value) => {
    this.setState({ faq: parseInt(value) });
  };

  render() {
    return (
      <section
        className="iq-main-slider p-0 iq-rtl-direction"
        style={{ position: "inherit" }}
      >
        <main id="main" className="site-main">
          <Container id="set-privacy-policy">
            <Row>
              <Col lg="12" sm="12" className="p-0">
                <div className="iq-privacy-policy">
                  <div className="privacy-text-sec">
                    <p>Mission and Purpose</p>
                  </div>
                  <div className="iq-privacy-policy1">
                    <div className="mission-purpose">
                      <p>Mission</p>
                    </div>
                    <div className="set-bottom-privacy">
                      <div className="privacy-text-set">
                        <p>
                          At the heart of our mission lies a profound
                          commitment: empowering content creators and visionary
                          business owners to ascend to new heights in the
                          digital realm. We aspire to catalyze a revolution,
                          enabling individuals not just to navigate but to shape
                          the future of media in the era of Web 3.0. We champion
                          the spirit of ownership and control, placing the power
                          firmly back into the hands of those who craft
                          compelling narratives and inspire global audiences.
                        </p>

                        <p>
                          Our mission transcends traditional boundaries,
                          pioneering the way for a transformative era in
                          streaming media. We envision a landscape where
                          creators and entrepreneurs are not confined by
                          limitations but are liberated by boundless
                          possibilities. We embark on a journey to build a
                          blockchain-based streaming media television network
                          and streaming media channels that serve as the very
                          essence of empowerment, allowing creators to
                          seamlessly distribute their artistry and curated
                          merchandise directly to their cherished customers,
                          ardent fans, and devoted followers.
                        </p>

                        <p>
                          This mission is a testament to our belief in the
                          symbiotic relationship between creativity and
                          technology, where innovation meets imagination. We are
                          devoted to nurturing an ecosystem where innovation
                          thrives, a sanctuary where artistic brilliance finds
                          its voice and entrepreneurial dreams find their wings.
                          By intertwining the power of blockchain with the
                          artistry of content creation, we usher in an era where
                          authenticity, creativity, and entrepreneurship
                          converge in a harmonious symphony.
                        </p>

                        <p>
                          In the tapestry of our mission, every keystroke, every
                          pixel, and every transaction is infused with the
                          essence of empowerment and independence. We are not
                          just building a streaming platform; we are
                          architecting a haven where dreams take flight, where
                          artists and business visionaries are not just pioneers
                          but the architects of their destiny. Together, we are
                          redefining the future of streaming media, one
                          empowered creator, one thriving business, and one
                          inspired viewer at a time.
                        </p>

                        <p>
                          Join us in this transformative journey, where the
                          intersection of creativity and technology gives rise
                          to a revolution that resonates across the digital
                          cosmos. Together, we are not just shaping the future;
                          we are crafting a legacy, where ownership, creativity,
                          and community reign supreme. Welcome to a new era of
                          digital empowerment, where the world is your canvas,
                          and your imagination knows no bounds.
                        </p>
                      </div>

                      <div className="purpose-details">
                        <div className="mission-purpose">
                          <p>Purpose</p>
                        </div>
                        <div className="privacy-text-set">
                          <p>
                            At the core of our existence, we are driven by a
                            profound purpose: to illuminate the path toward
                            financial independence for every soul on this
                            planet. Our vision transcends geographical
                            boundaries and societal constraints, aiming to
                            bestow upon every individual the power to not just
                            survive, but to thrive. We embark on a
                            transformative journey, pioneering the evolution of
                            earning a livelihood through the boundless reaches
                            of the digital universe.
                          </p>

                          <p>
                            VEUIT stands as the embodiment of this vision, a
                            beacon of hope and opportunity in a world that is
                            increasingly interconnected yet often unequal. We
                            are architects of empowerment, breaking down
                            barriers and redefining the very essence of work.
                            Through our revolutionary platform, we empower
                            businesses and content creators alike to transcend
                            limitations, fostering an environment where
                            efficiency meets creativity, and profitability knows
                            no bounds.
                          </p>

                          <p>
                            We do not merely provide a service; we usher in a
                            new era of economic liberation. In the realm of
                            VEUIT, every individual is not just a participant
                            but a protagonist in their own story of financial
                            emancipation. We dismantle the traditional norms of
                            employment, offering a digital sanctuary where
                            passion and dedication translate into sustenance and
                            prosperity. Whether you're an aspiring entrepreneur,
                            a gifted artist, or a skilled artisan, VEUIT opens
                            the doorways to self-reliance, allowing you to carve
                            your niche and earn a dignified living from any
                            corner of the globe.
                          </p>

                          <p>
                            Our purpose goes beyond mere transactions; it
                            encapsulates the spirit of transformation. We are
                            catalysts for change, empowering dreams to take
                            flight and ambitions to materialize. In the tapestry
                            of VEUIT, economic barriers crumble, and
                            opportunities flourish. We are not just
                            facilitators; we are enablers of dreams, architects
                            of destinies, and champions of a world where
                            financial freedom is not a luxury but a fundamental
                            right.
                          </p>

                          <p>
                            Join us in this profound journey where the
                            boundaries of possibility are stretched, and the
                            horizons of opportunity expand infinitely. Together,
                            let us shape a world where every individual has the
                            chance to craft their own destiny, where the pursuit
                            of passion is intertwined with the means of
                            livelihood. VEUIT is not just a platform; it is a
                            movement—a movement toward a future where every
                            soul, regardless of origin or circumstance, can
                            soar, thrive, and prosper. Welcome to a world where
                            earning a living is not just a pursuit but a
                            profound, empowering reality.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MissionPurpose);
