import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationManager } from "react-notifications";
import UserService from "../../services/user/user.service";
import Backdrop from "@material-ui/core/Backdrop";
import AppConfig from "../../config/app.config";
import { ReactComponent as VeuitLogo } from "../../assets/images/veuit-logo.svg";
import { ReactComponent as Failure } from "../../assets/images/Failure.svg";
import { ReactComponent as Ongoing } from "../../assets/images/Ongoing.svg";
import { ReactComponent as Somthingwrong } from "../../assets/images/somthingwrong.svg";
import { ReactComponent as Success } from "../../assets/images/Success.svg";
import axios from "axios";
import {
  selectEmployeeData,
  selectSigninRedirectPath,
} from "../../store/home/home.selectors";
import { setSigninRedirectPath } from "../../store/home/home.actions";

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
    selectSigninRedirectPath: selectSigninRedirectPath(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setSigninRedirectPath }, dispatch),
});

export class PaymentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      paymentStatus: 1,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.validatePayment();
  }

  handleBackButton = () => {
    if (this.props.selectSigninRedirectPath != null) {
      const path = this.props.selectSigninRedirectPath;
      this.props.setSigninRedirectPath({
        signinRedirectPath: null,
      });
      this.props.history.push(path);
    } else {
     // this.props.history.goBack();
      this.props.history.push("/");
    }
  };

  validatePayment = () => {
    this.setState({ isLoading: 1 });
    const orderId = parseInt(window.location.pathname.split("/")[3]);
    const payload = {
      orderId,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
    };
    UserService.getPaymentStatus(payload)
      .then(async (res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0, paymentStatus: 3 });
        } else {
          if (res["status"]) {
            if (res["data"] != null && res["data"].orderStatus !== null) {
              const orderStatus = res["data"].orderStatus;
              var paymentStatus = 3;
              if (orderStatus === "PAYMENT_COMPLETE") {
                // try {
                //   const employeeId = this.props.employeeData?.employeeId;
                //   if (employeeId !== null && employeeId !== undefined) {
                //     const txnPayload = {
                //       movieId: res["data"].productID,
                //       movieName: res["data"].productName,
                //       moviePrice: res["data"].productPrice,
                //       userId: employeeId,
                //     };
                //     // console.log(txnPayload);
                //     // const txnRes =
                //     await axios
                //       .post(
                //         "https://s7szw6lhpf.execute-api.us-east-1.amazonaws.com/dev/addTransactionToBlockchain",
                //         txnPayload
                //       )
                //       .then((result) => {
                //         const payloadTransaction = {
                //           orderId,
                //           transactionAddress: result.data.response,
                //         };
                //         UserService.updateTransactionAddress(payloadTransaction)
                //           .then(async (res) => {
                //             if (res.error != null) {
                //               this.setState({ isLoading: 0 });
                //             }
                //           })
                //           .catch((err) => {
                //             NotificationManager.error(err, "", 2000);
                //             this.setState({ isLoading: 0 });
                //           });
                //       })
                //       .catch((error) => ({ error }));
                //     // console.log(txnRes);
                //   }
                // } catch (inErr) {
                //   // console.log(inErr)
                // }
                paymentStatus = 0;
                //NotificationManager.success("Transaction Success", "", 2000);
              }
              if (orderStatus === "PAYMENT_FAIL") {
                paymentStatus = 1;
                //NotificationManager.error("Transaction Failed", "", 2000);
              }
              if (orderStatus === "PAYMENT_PROCESS") {
                paymentStatus = 2;
               // NotificationManager.success("Transaction Ongoing", "", 2000);
              }
              this.setState({ isLoading: 0, paymentStatus });
            }
          } else {
           // NotificationManager.error(res["message"], "", 2000);
            this.setState({ isLoading: 0, paymentStatus: 3 });
          }
        }
      })
      .catch((err) => {
      //  NotificationManager.error(err, "", 2000);
        this.setState({ isLoading: 0, paymentStatus: 3 });
      });
  };

  render() {
    return (
      <section className="sign-in-page">
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          <Container>
            <Row className="row justify-content-center align-items-center height-self-center">
              <Col
                lg="5"
                className="align-self-center forgot-pwd-height"
                id="set-sign-in"
              >
                <div className="sign-user_card ">
                  <div className="sign-in-page-data text-center">
                    <div className="sign-in-from m-auto">
                      <div className="sign-in-logo">
                        <Link to="/">
                          <VeuitLogo />
                        </Link>
                      </div>
                      <div className="form-bg-mobile">
                        {this.state.paymentStatus === 0 ? (
                          <Success className="payment-img" />
                        ) : this.state.paymentStatus === 1 ? (
                          <Failure className="payment-img" />
                        ) : this.state.paymentStatus === 2 ? (
                          <Ongoing className="payment-img" />
                        ) : (
                          <Somthingwrong className="payment-img" />
                        )}
                        {this.state.paymentStatus === 0 ? (
                          <h3 className="mt-3 mb-0 text-success">
                            <strong>Success !</strong>
                          </h3>
                        ) : this.state.paymentStatus === 1 ? (
                          <h3 className="mt-3 mb-0 text-danger">
                            <strong>Failure</strong>
                          </h3>
                        ) : this.state.paymentStatus === 2 ? (
                          <h3 className="mt-3 mb-0" id="ongoing-color">
                            <strong>Ongoing</strong>
                          </h3>
                        ) : (
                          <h3 className="mt-3 mb-0 text-danger">
                            <strong>Oops</strong>
                          </h3>
                        )}
                        <p className="text-white" id="payment-msg">
                          {this.state.paymentStatus === 0
                            ? "Congratulations! Your transaction was successful. Thank you for your purchase!"
                            : this.state.paymentStatus === 1
                            ? "Oops! Your transaction was unsuccessful. Please try again or contact our customer support for assistance."
                            : this.state.paymentStatus === 2
                            ? "Your transaction is in progress. Please wait while we process your payment. Thank you for your patience."
                            : "Something went wrong couldn't get your payment status"}
                        </p>
                        <div
                          className="d-inline-block w-100"
                          onClick={this.handleBackButton}
                        >
                          <Link
                            to="#"
                            className="btn btn-primary mt-3"
                            id="payment-back"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStatus);
