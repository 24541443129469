import React from "react";
import Error_Ilu from "../../assets/images/404_Error.png";

class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <section
                className="sign-in-page error-content"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Error_Ilu}
              style={{ maxHeight: 320, maxWidth: 320 }}
              className="error-image"
              alt=""
            />
          </div>
          <h1
            style={{ textAlign: "center", fontSize: "120px" }}
            className="error-code"
          >
            404
          </h1>
          <h4
            style={{
              color: "white",
              textAlign: "center",
              wordSpacing: 4,
              letterSpacing: 2,
            }}
            className="error-text"
          >
            SORRY,PAGE NOT FOUND
          </h4>
        </div> */}
                <div className="new-error-page">
                    <div className="new-er">
                        <img
                            className="new-er-img-web"
                            src={require("../../assets/images/Error.png")}
                        />
                        <img
                            className="new-er-img-mobile"
                            src={require("../../assets/images/Error2.png")}
                        />
                        <div className="new-er-btn">
                            <button className="goback-btn" onClick={this.goBack}>Go Back</button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Error;
