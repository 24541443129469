import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validator from "validator";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Backdrop from "@material-ui/core/Backdrop";
import { Link } from "react-router-dom";
import { ReactComponent as VeuitLogo } from "../../assets/images/veuit-logo.svg";
import { selectEmployeeData } from "../../store/home/home.selectors";

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class ForgotPassWord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      email: "",
    };
    this.handleEmail = this.handleEmail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      this.props.history.push("/");
    } else {
      this.setState({ email: "" });
    }
  }

  handleEmail(event) {
    const removeExtraSpace = (s) => s.replace(/\s/g, "");
    this.setState({ email: removeExtraSpace(event.target.value) });
  }

  forgotPass = () => {
    if (this.state.email === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000);
    } else if (!validator.isEmail(this.state.email)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000);
    } else if (this.state.password !== "" && this.state.username !== "") {
      const payload = {
        emailAddress: this.state.email,
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        appId: AppConfig.API.APP_ID
      };

      this.setState({ isLoading: 1 });
      UserService.sendResetPasswordLink(payload)
        .then((res) => {
          if (res.error != null) {
            this.setState({ isLoading: 0, email: "" });
            NotificationManager.error(
              res.error.response.data.message,
              "",
              2000
            );
          } else {
            if (res["status"]) {
              if (res["message"] === "success") {
                NotificationManager.success(
                  "A link has been sent to your Email.",
                  "",
                  2000
                );
                this.timeoutHandle = setTimeout(() => {
                  this.setState({ isLoading: 0, email: "" });

                  const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL);
                  const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
                  window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
                }, 3000);
              } else {
                this.setState({ isLoading: 0, email: "" });
                NotificationManager.error(res["message"], "", 2000);
              }
            } else {
              this.setState({ isLoading: 0, email: "" });
              NotificationManager.error(res["message"], "", 2000);
            }
          }
        })
        .catch((err) => {
          this.setState({ isLoading: 0 });
          NotificationManager.error(err, "", 2000);
        });
    }
  };

  onLogin = () => {
    const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL);
    const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
    window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
  }

  render() {
    return (
      <section className="sign-in-page">
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          ""
        )}
        <Container>
          <Row className="row justify-content-center align-items-center height-self-center">
            <div id="set-sign-in1">
              <Col
                className="align-self-center forgot-pwd-height"
                id="set-sign-in"
              >
                <div className="sign-user_card ">
                  <div className="sign-in-page-data">
                    <div className="sign-in-from m-auto">
                      <div className="sign-in-logo">
                        <Link to="/">
                          {" "}
                          <VeuitLogo id="info-logopages" />
                        </Link>
                      </div>
                      <div className="form-bg-mobile">
                        <div className="form-bg-mobile1" id="form-bg-mobile1">
                          <p className="forgot-pwd">Forgot Password?</p>
                          <Form
                            className="set-input-width"
                            id="set-input-width"
                          >
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control mb-0 forgot-input"
                                id="exampleInputEmail2"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.handleEmail}
                                autoComplete="off"
                                required
                              />
                            </div>
                            <p className="text-body-pwd">
                              Enter your email and we'll send you a link to get
                              back into your account.
                            </p>
                            <div className="sign-info">
                              <Button
                                className={`btn ${validator.isEmail(this.state.email)
                                  ? ""
                                  : "disabled"
                                  }`}
                                id="forgot-inbtn"
                                onClick={() => this.forgotPass()}
                              >
                                Send Login Link
                              </Button>
                            </div>
                            <Link to="#" onClick={this.onLogin}>
                              <p className="back-login">Back to login</p>
                            </Link>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassWord);
