import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-notifications/lib/notifications.css";
import Backdrop from "@material-ui/core/Backdrop";
import AppConfig from "../../config/app.config";
import PodCastMap from "../../components/podcasts/podcast.component";
import {
  selectPodcast,
  selectEmployeeData,
} from "../../store/home/home.selectors";
import { podcastSyncStart } from "../../store/home/home.actions";

const mapStateToProps = (state) => {
  return {
    selectPodcast: selectPodcast(state),
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      podcastSyncStart,
    },
    dispatch
  ),
});

export class ViewAllPodcast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 2,
      dataType: "",
      dataTypeName: "",
      dataTypeId: "",
      dataMap: [],
      checkingUpdate: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { selectPodcast, podcastSyncStart } = this.props;

    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      employeeId: this.props.employeeData?.employeeId,
      loginEmployeeId: this.props.employeeData?.employeeId,
      appId: AppConfig.API.APP_ID,
    };

    const pathArray = window.location.pathname.split("/");
    if (pathArray[1] !== null && pathArray[1] === "view-podcast") {
      this.setState({ dataTypeName: "Podcasts" });
      if (selectPodcast.initialSynced && selectPodcast.podcastList !== null) {
        this.setState({ dataMap: selectPodcast.podcastList });
      } else {
        podcastSyncStart(payload);
      }
      this.interval = setInterval(() => this.setState({ isLoading: 0 }), 5000);
    } else {
      this.props.history.goBack();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectPodcast } = this.props;
    if (selectPodcast.initialSynced) {
      if (prevProps.selectPodcast.podcastList !== selectPodcast.podcastList) {
        this.setState({ dataMap: selectPodcast.podcastList });
      }
    }
  }

  render() {
    return (
      <section
        className="iq-main-slider p-0 iq-rtl-direction"
        style={{ position: "unset" }}
        id="iq-main-slider"
      >
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          ""
        )}

        {this.state.isLoading === 2 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: window.innerHeight,
                width: "100%",
              }}
            >
              <img
                src={require("../../assets/images/Veuit-Spinner.gif")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </div>
          </>
        ) : (
          <>
            {/* <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{ backgroundImage: `url(${bannerImage})`, height: '50vh' }}>
                        </div> */}
            <div className="main-content" id="set-header-line">
              <section id="iq-favorites">
                <Container fluid>
                  <h3
                    className="text-uppercase view-all-text-movie"
                    style={{ textAlign: "center" }}
                  >
                    {this.state.dataTypeName}
                  </h3>

                  <Row
                    sm="12"
                    className="overflow-hidden"
                    style={{ paddingLeft: "60px", paddingRight: "60px" }}
                    id="viewall-row"
                  >
                    {this.state.dataMap?.map((item, key) => {
                      return (
                        <Col
                          lg="3"
                          sm="4"
                          md="3"
                          xl="2"
                          key={key}
                          style={{ paddingTop: "20px", paddingBottom: "20px" }}
                          id="view-all-podcast"
                        >
                          <PodCastMap resultObject={item} id={item.podCastId} />
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
              </section>
            </div>
          </>
        )}
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllPodcast);
