import purchaseSeriesListActionTypes from "./PurchaseSeriesList.types"
import AppConfig from "../../config/app.config"
import UserService from "../../services/user/user.service"
import {
  purchaseSeriesListSuccess,
  purchaseSeriesListFailure,
} from "./PurchaseSeriesList.actions"
import { takeLeading, call, put, all } from "redux-saga/effects"

export function* purchaseSeriesListSaga(payload) {
  const purchaseData = {
    subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
    verticalId: AppConfig.API.VERTICAL_ID,
    isPurchase: 1,
    employeeId: window.localStorage.getItem("employeeId"),
  }
  try {
    const res = yield call(UserService.getPurchaseSeriesList, purchaseData)
    if (res.error != null) {
      yield put(
        purchaseSeriesListFailure({ err: res.error.response.data.message })
      )
    } else {
      yield put(purchaseSeriesListSuccess(res.data))
    }
  } catch (err) {
    put(purchaseSeriesListFailure({ err }))
  }
}

export function* purchaseStart() {
  yield takeLeading(
    purchaseSeriesListActionTypes.API_CALL_START,
    purchaseSeriesListSaga
  )
}

export function* purchaseSaga() {
  yield all([call(purchaseStart)])
}
