import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import FsLightbox from "fslightbox-react";
import Banner from "../../components/banner/banner.component";
import SeriesList from "../../components/series-list/series-list.component";
import InfluencerList from "../../components/influencer-list/influencer-list.component";
import BrandList from "../../components/brand-list/brand-list.component";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
//import Podcast from '../../components/podcasts/podcast-list.component';
import Musicartistlist from "../../components/MusicArtist/musicartist-list.component";
//import WEI from '../../assets/images/WEI.png';
import ReactJWPlayer from "react-jw-player";
import {
  selectHomeDataStatus,
  selectTabList,
  selectNewRelease,
  selectOriginalSeries,
  selectBrands,
  selectBrandsPartners,
  selectExclusiveEvent,
  selectFeaturedChannels,
  selectTvChannels,
  selectPodcast,
  selectTrendingLives,
  selectChannelCategory,
  selectClassic,
  selectIndependent,
  selectEmployeeData,
  selectFeatured,
} from "../../store/home/home.selectors";
import {
  homeSyncStart,
  tabListSyncStart,
  newRealeseSyncStart,
  originalSeriesSyncStart,
  brandsSyncStart,
  brandsPartnersSyncStart,
  exclusiveEventSyncStart,
  featuredChannelsSyncStart,
  tvChannelsSyncStart,
  podcastSyncStart,
  trendingLivesSyncStart,
  channelCategorySyncStart,
  classicSyncStart,
  independentSyncStart,
  setSigninRedirectPath,
  featuredSyncStart,
} from "../../store/home/home.actions";
import FeaturedChannelList from "../../components/featured-channel/featured-channel.component";
import MovieList from "../../components/movie-list/movie-list.component";
import TvChannelList from "../../components/tvchannel-list/tvchannel-list.component";
//import eductionImage from '../../assets/images/VeuitBlankimg.png';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const mapStateToProps = (state) => {
  return {
    selectHomeDataStatus: selectHomeDataStatus(state),
    selectTabList: selectTabList(state),
    selectNewRelease: selectNewRelease(state),
    selectOriginalSeries: selectOriginalSeries(state),
    selectBrands: selectBrands(state),
    selectBrandsPartners: selectBrandsPartners(state),
    selectExclusiveEvent: selectExclusiveEvent(state),
    selectFeaturedChannels: selectFeaturedChannels(state),
    selectTvChannels: selectTvChannels(state),
    selectPodcast: selectPodcast(state),
    selectTrendingLives: selectTrendingLives(state),
    selectChannelCategory: selectChannelCategory(state),
    selectClassic: selectClassic(state),
    selectIndependent: selectIndependent(state),
    employeeData: selectEmployeeData(state),
    selectFeatured: selectFeatured(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      homeSyncStart,
      tabListSyncStart,
      newRealeseSyncStart,
      originalSeriesSyncStart,
      brandsSyncStart,
      brandsPartnersSyncStart,
      exclusiveEventSyncStart,
      featuredChannelsSyncStart,
      tvChannelsSyncStart,
      podcastSyncStart,
      trendingLivesSyncStart,
      channelCategorySyncStart,
      classicSyncStart,
      independentSyncStart,
      setSigninRedirectPath,
      featuredSyncStart,
    },
    dispatch
  ),
});

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trailerPlay: false,
      trailerUrl: "",
      isLoading: 1,
      checkingUpdate: false,
      bannerMap: [],
      newReleaseMap: [],
      originalSeriesMap: [],
      brandsMap: [],
      brandsPartnersMap: [],
      exclusiveEventMap: [],
      featuredChannelsMap: [],
      tvChannelsMap: [],
      podcastMap: [],
      trendinglivesMap: [],
      channelCategoryMap: [],
      classicMap: [],
      independentMap: [],
      securityTabListMap: [],
      isLogin: false,
      isShowModel: false,
      modelUrl: "",
      featuredMap: [],
      isChannelLogin: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      homeSyncStart,
      selectHomeDataStatus,
      tabListSyncStart,
      newRealeseSyncStart,
      originalSeriesSyncStart,
      brandsSyncStart,
      selectOriginalSeries,
      selectBrands,
      selectBrandsPartners,
      brandsPartnersSyncStart,
      selectExclusiveEvent,
      exclusiveEventSyncStart,
      selectFeaturedChannels,
      featuredChannelsSyncStart,
      selectTvChannels,
      tvChannelsSyncStart,
      selectPodcast,
      podcastSyncStart,
      selectTrendingLives,
      trendingLivesSyncStart,
      channelCategorySyncStart,
      classicSyncStart,
      independentSyncStart,
      selectFeatured,
      featuredSyncStart,
    } = this.props;

    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      employeeId: this.props.employeeData?.employeeId,
      loginEmployeeId: this.props.employeeData?.employeeId,
      appId: AppConfig.API.APP_ID,
      timeZone: null,
    };

    this.props.setSigninRedirectPath({ signinRefirectPath: null });
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      payload.timeZone = null;
      this.setState({ isLogin: true });
    } else {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      payload.timeZone = timeZone;
      this.setState({ isLogin: false });
    }

    if (
      selectHomeDataStatus.initialSynced &&
      selectHomeDataStatus.syncinghome &&
      selectHomeDataStatus.bannerList !== null
    ) {
      this.setState({ bannerMap: selectHomeDataStatus.bannerList });
    } else {
      homeSyncStart(payload);
    }

    if (
      selectChannelCategory.initialSynced &&
      selectChannelCategory.bannerList !== null
    ) {
      this.setState({
        channelCategoryMap: selectChannelCategory.channelCategoryList,
      });
    } else {
      channelCategorySyncStart(payload);
    }

    newRealeseSyncStart(payload);
    classicSyncStart(payload);
    independentSyncStart(payload);

    if (
      selectOriginalSeries.initialSynced &&
      selectOriginalSeries.originalSeriesList !== null
    ) {
      this.setState({
        originalSeriesMap: selectOriginalSeries.originalSeriesList,
      });
    } else {
      originalSeriesSyncStart(payload);
    }

    if (selectBrands.initialSynced && selectBrands.brandsList !== null) {
      this.setState({ brandsMap: selectBrands.brandsList });
    } else {
      brandsSyncStart(payload);
    }

    if (
      selectBrandsPartners.initialSynced &&
      selectBrandsPartners.brandsPartnersList !== null
    ) {
      this.setState({
        brandsPartnersMap: selectBrandsPartners.brandsPartnersList,
      });
    } else {
      brandsPartnersSyncStart(payload);
    }

    if (
      selectFeaturedChannels.initialSynced &&
      selectFeaturedChannels.featuredChannelsList !== null
    ) {
      this.setState({
        featuredChannelsMap: selectFeaturedChannels.featuredChannelsList,
      });
    } else {
      featuredChannelsSyncStart(payload);
    }

    if (
      selectTvChannels.initialSynced &&
      selectTvChannels.tvChannelsList !== null
    ) {
      this.setState({ tvChannelsMap: selectTvChannels.tvChannelsList });
    } else {
      tvChannelsSyncStart(payload);
    }
    if (
      selectExclusiveEvent.initialSynced &&
      selectExclusiveEvent.exclusiveeventList !== null
    ) {
      this.setState({
        exclusiveEventMap: selectExclusiveEvent.exclusiveeventList,
      });
    } else {
      exclusiveEventSyncStart(payload);
    }

    if (selectPodcast.initialSynced && selectPodcast.podcastList !== null) {
      this.setState({ podcastMap: selectPodcast.podcastList });
    } else {
      podcastSyncStart(payload);
    }

    if (
      selectTrendingLives.initialSynced &&
      selectTrendingLives.trendinglivesList !== null
    ) {
      this.setState({
        trendinglivesMap: selectTrendingLives.trendinglivesList,
      });
    } else {
      trendingLivesSyncStart(payload);
    }

    if (selectFeatured.initialSynced && selectFeatured.featuredList !== null) {
      this.setState({ featuredMap: selectFeatured.featuredList });
    } else {
      featuredSyncStart(payload);
    }

    this.interval = setInterval(() => this.setState({ isLoading: 0 }), 5000);

    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      if (selectTabList.initialSynced && selectTabList.tabList !== null) {
        this.setState({ securityTabListMap: selectTabList.tabList });
      } else {
        tabListSyncStart(payload);
      }
    } else {
      this.props.history.push("/");
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      selectHomeDataStatus,
      selectNewRelease,
      selectOriginalSeries,
      selectBrands,
      selectBrandsPartners,
      selectExclusiveEvent,
      selectFeaturedChannels,
      selectTvChannels,
      selectPodcast,
      selectTrendingLives,
      selectChannelCategory,
      selectClassic,
      selectIndependent,
      selectTabList,
      selectFeatured,
    } = this.props;
    if (selectHomeDataStatus.initialSynced) {
      if (
        prevProps.selectHomeDataStatus.bannerList !==
        selectHomeDataStatus.bannerList
      ) {
        if (selectHomeDataStatus.syncinghome) {
          this.setState({ bannerMap: selectHomeDataStatus.bannerList });
        } else {
          if (selectHomeDataStatus.message != null) {
            this.setState({ bannerMap: [] });
            NotificationManager.error(selectHomeDataStatus.message, "", 2000);
          }
        }
      }
    }

    if (selectChannelCategory.initialSynced) {
      if (
        prevProps.selectChannelCategory.channelCategoryList !==
        selectChannelCategory.channelCategoryList
      ) {
        this.setState({
          channelCategoryMap: selectChannelCategory.channelCategoryList,
          checkingUpdate: !this.state.checkingUpdate,
        });
      }
    }

    if (selectNewRelease.initialSynced) {
      if (
        prevProps.selectNewRelease.newReleaseList !==
        selectNewRelease.newReleaseList
      ) {
        this.setState({
          newReleaseMap: selectNewRelease.newReleaseList,
          checkingUpdate: !this.state.checkingUpdate,
        });
      }
    }

    if (selectOriginalSeries.initialSynced) {
      if (
        prevProps.selectOriginalSeries.originalSeriesList !==
        selectOriginalSeries.originalSeriesList
      ) {
        this.setState({
          originalSeriesMap: selectOriginalSeries.originalSeriesList,
        });
      }
    }

    if (selectBrands.initialSynced) {
      if (prevProps.selectBrands.brandsList !== selectBrands.brandsList) {
        this.setState({ brandsMap: selectBrands.brandsList });
      }
    }

    if (selectBrandsPartners.initialSynced) {
      if (
        prevProps.selectBrandsPartners.brandsPartnersList !==
        selectBrandsPartners.brandsPartnersList
      ) {
        this.setState({
          brandsPartnersMap: selectBrandsPartners.brandsPartnersList,
        });
      }
    }

    if (selectExclusiveEvent.initialSynced) {
      if (
        prevProps.selectExclusiveEvent.exclusiveeventList !==
        selectExclusiveEvent.exclusiveeventList
      ) {
        this.setState({
          exclusiveEventMap: selectExclusiveEvent.exclusiveeventList,
        });
      }
    }

    if (selectFeaturedChannels.initialSynced) {
      if (
        prevProps.selectFeaturedChannels.featuredChannelsList !==
        selectFeaturedChannels.featuredChannelsList
      ) {
        this.setState({
          featuredChannelsMap: selectFeaturedChannels.featuredChannelsList,
        });
      }
    }

    if (selectTvChannels.initialSynced) {
      if (
        prevProps.selectTvChannels.tvChannelsList !==
        selectTvChannels.tvChannelsList
      ) {
        this.setState({ tvChannelsMap: selectTvChannels.tvChannelsList });
      }
    }

    if (selectPodcast.initialSynced) {
      if (prevProps.selectPodcast.podcastList !== selectPodcast.podcastList) {
        this.setState({ podcastMap: selectPodcast.podcastList });
      }
    }

    if (selectTrendingLives.initialSynced) {
      if (
        prevProps.selectTrendingLives.trendinglivesList !==
        selectTrendingLives.trendinglivesList
      ) {
        this.setState({
          trendinglivesMap: selectTrendingLives.trendinglivesList,
        });
      }
    }

    if (selectClassic.initialSynced) {
      if (prevProps.selectClassic.classicList !== selectClassic.classicList) {
        this.setState({
          classicMap: selectClassic.classicList,
          checkingUpdate: !this.state.checkingUpdate,
        });
      }
    }

    if (selectIndependent.initialSynced) {
      if (
        prevProps.selectIndependent.independentList !==
        selectIndependent.independentList
      ) {
        this.setState({
          independentMap: selectIndependent.independentList,
          checkingUpdate: !this.state.checkingUpdate,
        });
      }
    }

    if (selectFeatured.initialSynced) {
      if (
        prevProps.selectFeatured.featuredList !== selectFeatured.featuredList
      ) {
        this.setState({
          featuredMap: selectFeatured.featuredList,
          checkingUpdate: !this.state.checkingUpdate,
        });
      }
    }

    if (selectTabList.initialSynced) {
      if (prevProps.selectTabList.tabList !== selectTabList.tabList) {
        this.setState({
          securityTabListMap: selectTabList.tabList,
          checkingUpdate: !this.state.checkingUpdate,
        });
      }
    }
  }

  playTrailer = (trailerUrl) => {
    this.setState({
      trailerUrl: trailerUrl,
      trailerPlay: !this.state.trailerPlay,
    });
  };

  addToWatchList = (movieId, movieWatchListId, tagName) => {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      const payload = {
        movieId: movieId,
        employeeId: this.props.employeeData?.employeeId,
        movieWatchListId: movieWatchListId,
      };

      UserService.addToWatchList(payload)
        .then((res) => {
          if (res.error != null) {
            NotificationManager.warning(
              res.error.response.data.message,
              "",
              2000
            );
          } else {
            if (res["status"]) {
              const payloadData = {
                subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
                verticalId: AppConfig.API.VERTICAL_ID,
                employeeId: this.props.employeeData?.employeeId,
                loginEmployeeId: this.props.employeeData?.employeeId,
              };

              this.props.newRealeseSyncStart(payloadData);
              this.props.classicSyncStart(payloadData);
              this.props.independentSyncStart(payloadData);
              if (movieWatchListId !== -1) {
                NotificationManager.success(
                  "Movie Removed from My Watchlist.",
                  "",
                  2000
                );
              } else {
                NotificationManager.success(
                  "Movie Added to My Watchlist.",
                  "",
                  2000
                );
              }
            } else {
              NotificationManager.error(res["message"], "", 2000);
            }
          }
        })
        .catch((err) => {
          NotificationManager.error(err, "", 2000);
        });
    } else {
      this.props.setSigninRedirectPath({ signinRefirectPath: "/" });
      const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL);
      const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
    }
  };

  animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };

  handleClose = () => {
    this.setState({ isShowModel: false, modelUrl: "" });
  };

  handleShow = () => {
    this.setState({
      isShowModel: true,
      modelUrl:
        "https://dflpkq3kfltnp.cloudfront.net/157afb11-624b-4b76-8ef7-1b585aaa6cd2/hls/jumanji.m3u8",
    });
  };

  handleWorldEducationShow = (event) => {
    event.target.blur();
    this.setState({
      isShowModel: true,
      modelUrl:
        "https://ciright-public.s3.amazonaws.com/wei_draft_07.2+(1080p)+(1).mp4",
    });
  };

  render() {
    return (
      <div>
        {this.state.isLoading === 1 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: window.innerHeight,
                width: "100%",
              }}
            >
              <img
                src={require("../../assets/images/Veuit-Spinner.gif")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </div>
          </>
        ) : (
          <>
            <Modal
              show={this.state.isShowModel}
              centered
              dialogClassName="modal-fullscreen"
              id="app-modal"
            >
              <div id="modal-content-wrapper">
                <Modal.Header
                  closeButton
                  onHide={this.handleClose}
                  backdrop="static"
                ></Modal.Header>
                <Modal.Body id="main-screen-modal">
                  <ReactJWPlayer
                    playerId="movie"
                    playerScript="https://cdn.jwplayer.com/libraries/zFFedrqG.js"
                    file={this.state.modelUrl}
                    isAutoPlay={true}
                    // aspectRatio="20:9"
                    customProps={{
                      playbackRateControls: [1, 1.25, 1.5],
                      cast: {},
                    }}
                  />
                </Modal.Body>
              </div>
            </Modal>
            {this.state.bannerMap != null && this.state.bannerMap.length > 0 ? (
              <section
                id="home"
                className="iq-main-slider p-0 iq-rtl-direction"
              >
                <hr className="set-gradient-border1"></hr>
                <FsLightbox
                  toggler={this.state.trailerPlay}
                  sources={[
                    <iframe
                      src={this.state.trailerUrl}
                      title=" "
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                      style={{ width: "700px", height: "650px" }}
                    />,
                  ]}
                />

                <div id="prevHome" className="swiper-button swiper-button-prev">
                  <i className="fa fa-chevron-left"></i>
                </div>
                <div id="nextHome" className="swiper-button swiper-button-next">
                  <i className="fa fa-chevron-right"></i>
                </div>

                <Swiper
                  navigation={{
                    prevEl: "#prevHome",
                    nextEl: "#nextHome",
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  onInit={() => {
                    this.animationInit();
                  }}
                  onSlideChangeTransitionStart={() => this.animationInit()}
                  loop={this.state.bannerMap.length > 1 ? true : false}
                  id="home-slider"
                  className="slider m-0 p-0"
                >
                  {this.state.bannerMap.map((item, key) => {
                    return (
                      <SwiperSlide
                        className="slide slick-bg s-bg-1"
                        as="li"
                        key={key}
                      >
                        <Banner
                          key={key}
                          originalSeriesMap={item}
                          playTrailer={this.playTrailer}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <hr className="set-gradient-border"></hr>
              </section>
            ) : (
              ""
            )}

            {this.state.newReleaseMap != null &&
              this.state.newReleaseMap.length > 0
              ? this.state.securityTabListMap
                .filter(
                  (item) =>
                    item.tabTypeUrl ===
                    "veuit-network-home-movie-new-releases.htm"
                )
                .map((item, key) => (
                  <MovieList
                    key={key}
                    moviesMap={this.state.newReleaseMap}
                    checkingUpdate={this.state.checkingUpdate}
                    id={17}
                    tagName={item.tabName}
                    playNowUrl="/movie-details"
                    viewAllUrl="/view-all/new-releases"
                    addToWatchList={this.addToWatchList}
                  />
                ))
              : ""}

            {this.state.classicMap != null && this.state.classicMap.length > 0
              ? this.state.securityTabListMap
                .filter(
                  (item) =>
                    item.tabTypeUrl ===
                    "veuit-network-home-movie-classics.htm"
                )
                .map((item, key) => (
                  <MovieList
                    key={key}
                    moviesMap={this.state.classicMap}
                    checkingUpdate={this.state.checkingUpdate}
                    id={18}
                    tagName={item.tabName}
                    playNowUrl="/movie-details"
                    viewAllUrl="/view-all/classic"
                    addToWatchList={this.addToWatchList}
                  />
                ))
              : ""}

            {this.state.independentMap != null &&
              this.state.independentMap.length > 0
              ? this.state.securityTabListMap
                .filter(
                  (item) =>
                    item.tabTypeUrl ===
                    "veuit-network-home-movie-independent.htm"
                )
                .map((item, key) => (
                  <MovieList
                    key={key}
                    moviesMap={this.state.independentMap}
                    checkingUpdate={this.state.checkingUpdate}
                    id={18}
                    tagName={item.tabName}
                    playNowUrl="/movie-details"
                    viewAllUrl="/view-all/independent"
                    addToWatchList={this.addToWatchList}
                  />
                ))
              : ""}

            {this.state.featuredMap != null &&
              this.state.featuredMap.length > 0 ? (
              <InfluencerList
                key={54}
                resultMap={this.state.featuredMap}
                id={54}
                tagName={"Featured"}
                viewAllUrl={`/view-all/featured`}
              />
            ) : (
              ""
            )}

            {this.state.channelCategoryMap != null &&
              this.state.channelCategoryMap.length > 0 ? (
              <>
                {this.state.channelCategoryMap?.map((item, key) => {
                  let number = key + 1;
                  if (key >= 0 && key <= 2) {
                    return (
                      <div key={key}>
                        {number % 2 === 0 ? (
                          <Musicartistlist
                            key={key}
                            resultMap={item.channelCategoryMap}
                            id={item.channelCategoryId}
                            tagName={item.channelCategoryName}
                            viewAllUrl={`/view-all/${item.channelCategoryId}`}
                          />
                        ) : (
                          <InfluencerList
                            key={key}
                            resultMap={item.channelCategoryMap}
                            id={item.channelCategoryId}
                            tagName={item.channelCategoryName}
                            viewAllUrl={`/view-all/${item.channelCategoryId}`}
                          />
                        )}
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
              </>
            ) : (
              ""
            )}

            {this.state.brandsPartnersMap != null &&
              this.state.brandsPartnersMap.length > 0 ? (
              <section id="iq-tvthrillers" className="s-margin">
                <Container fluid className="d-brand-con">
                  <Row className="set-brand-con">
                    <Col sm="12" className="overflow-hidden d-brand-networks">
                      <div
                        className="d-flex align-items-center justify-content-between"
                        id="d-brand-network"
                      >
                        <h3 className="main-title text-uppercase d-brand-network">
                          BRAND NETWORKS
                        </h3>
                      </div>
                      <div id="favorites-contens set-brand-border">
                        <div
                          id="prevBrand3"
                          className="swiper-button swiper-button-prev d-brand-prev"
                        >
                          <i className="fa fa-chevron-left d-icon"></i>
                        </div>
                        <div
                          id="nextBrand3"
                          className="swiper-button swiper-button-next d-brand-prev"
                        >
                          <i className="fa fa-chevron-right d-icon"></i>
                        </div>
                        <Swiper
                          navigation={{
                            prevEl: "#prevBrand3",
                            nextEl: "#nextBrand3",
                          }}
                          loop={true}
                          slidesPerView={1}
                          spaceBetween={20}
                          as="ul"
                          id="brand-slider"
                          className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                        >
                          {this.state.brandsPartnersMap.map((item, key) => {
                            return (
                              <SwiperSlide
                                className="slick-bg"
                                as="li"
                                key={key}
                                id="d-brand-video"
                              >
                                {item.contentType === "Video" ? (
                                  <div className="position-relative">
                                    <hr className="set-gradient-border"></hr>
                                    <div className="img-box exclusive-events">
                                      <video
                                        playsInline
                                        autoPlay
                                        loop
                                        muted
                                        className="event-videos"
                                      >
                                        <source
                                          src={item.contentUrl}
                                          type="video/mp4"
                                        />
                                      </video>
                                    </div>
                                    <hr className="set-gradient-border"></hr>
                                    <div
                                      className="block-description h-100"
                                      style={{
                                        justifyContent: "center",
                                        width: "100%",
                                        left: 0,
                                      }}
                                    >
                                      <div className="text-center d-brand-logo">
                                        {item.brandLogoUrl != null &&
                                          item.brandLogoUrl !== null &&
                                          item.brandLogoUrl !== "" ? (
                                          <img
                                            src={item.brandLogoUrl}
                                            alt={item.brandPartnerName}
                                            className="d-img1"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <br />
                                        <Button
                                          type="button"
                                          target="_blank"
                                          href={item.brandPartnerWebsiteUrl}
                                          variant="primary next action-button"
                                          className="d-brand-btn btn"
                                          style={{ color: "#fff" }}
                                        >
                                          View Now
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="position-relative exclusive-events">
                                    <hr className="set-gradient-border"></hr>
                                    <div className="img-box">
                                      <img
                                        src={item.contentUrl}
                                        className="img-fluid event-videos"
                                        alt=""
                                      />
                                    </div>
                                    <hr className="set-gradient-border"></hr>
                                    <div
                                      className="block-description h-100"
                                      style={{
                                        justifyContent: "center",
                                        width: "100%",
                                        left: 0,
                                      }}
                                    >
                                      <div className="text-center d-brand-logo">
                                        {item.brandLogoUrl != null &&
                                          item.brandLogoUrl !== null &&
                                          item.brandLogoUrl !== "" ? (
                                          <img
                                            src={item.brandLogoUrl}
                                            alt={item.brandPartnerName}
                                            className="d-img1"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <br />
                                        <Button
                                          type="button"
                                          target="_blank"
                                          href={item.brandPartnerWebsiteUrl}
                                          variant="primary next action-button"
                                          className="d-brand-btn btn"
                                          style={{ color: "#fff" }}
                                        >
                                          View Now
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            ) : (
              ""
            )}

            {this.state.brandsMap?.length > 0 ? (
              <BrandList
                resultMap={this.state.brandsMap}
                id={9}
                tagName="Brands"
                viewAllUrl="/view-all/brands"
              />
            ) : (
              ""
            )}

            {this.state.channelCategoryMap?.length > 2 ? (
              <>
                {this.state.channelCategoryMap?.map((item, key) => {
                  let number = key + 1;
                  if (key >= 3 && key <= 4) {
                    return (
                      <div key={key}>
                        {number % 2 === 0 ? (
                          <Musicartistlist
                            key={key}
                            resultMap={item.channelCategoryMap}
                            id={item.channelCategoryId}
                            tagName={item.channelCategoryName}
                            viewAllUrl={`/view-all/${item.channelCategoryId}`}
                          />
                        ) : (
                          <InfluencerList
                            key={key}
                            resultMap={item.channelCategoryMap}
                            id={item.channelCategoryId}
                            tagName={item.channelCategoryName}
                            viewAllUrl={`/view-all/${item.channelCategoryId}`}
                          />
                        )}
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
              </>
            ) : (
              ""
            )}

            {this.state.trendinglivesMap?.length > 0 ? (
              <Musicartistlist
                resultMap={this.state.trendinglivesMap}
                id={12}
                tagName="Featured Lives"
                viewAllUrl="/view-all/featured-lives"
              />
            ) : (
              ""
            )}

            {this.state.exclusiveEventMap?.length > 0 ? (
              <>
                <section id="iq-brand" className="s-margin">
                  <Container fluid className="d-brand-con">
                    <Row className="set-brand-con">
                      <Col sm="12" className="overflow-hidden d-brand-networks">
                        <div
                          className="d-flex align-items-center justify-content-between"
                          id="d-brand-network"
                        >
                          <h3 className="main-title text-uppercase d-brand-network">
                            {" "}
                            VEUIT EVENTS{" "}
                          </h3>
                        </div>
                        <div id="favorites-contens \">
                          <div
                            id="prevEvent"
                            className="swiper-button swiper-button-prev e-brand-prev"
                          >
                            <i className="fa fa-chevron-left d-icon"></i>
                          </div>
                          <div
                            id="nextEvent"
                            className="swiper-button swiper-button-next e-brand-prev"
                          >
                            <i className="fa fa-chevron-right d-icon"></i>
                          </div>
                          <Swiper
                            navigation={{
                              prevEl: "#prevEvent",
                              nextEl: "#nextEvent",
                            }}
                            loop={true}
                            slidesPerView={1}
                            spaceBetween={20}
                            id="event-slider"
                            as="ul"
                            className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                          >
                            {this.state.exclusiveEventMap?.map((item, key) => {
                              const contentType = item.content.split("?");

                              return (
                                <SwiperSlide
                                  className="slick-bg"
                                  as="li"
                                  key={key}
                                  id="d-brand-video"
                                >
                                  <div className="position-relative">
                                    <hr className="set-gradient-border"></hr>
                                    <div className="img-box exclusive-event-events">
                                      {contentType[1] === "type=Image" ? (
                                        <img
                                          src={contentType[0]}
                                          className="img-fluid event-video-events"
                                          alt=""
                                        />
                                      ) : (
                                        <video
                                          playsInline
                                          autoPlay
                                          loop
                                          muted
                                          className="event-video-events"
                                        >
                                          <source
                                            src={item.content}
                                            type="video/mp4"
                                          />
                                        </video>
                                      )}
                                    </div>
                                    <hr className="set-gradient-border"></hr>
                                    <div
                                      className="block-description h-100"
                                      style={{
                                        justifyContent: "center",
                                        width: "100%",
                                        left: 0,
                                      }}
                                    >
                                      <div className="text-center d-brand-logo">
                                        <img
                                          src={item.image}
                                          alt={item.eventTitle}
                                          className="d-img-brand"
                                        />
                                        <br />
                                        {item.isExternalLink === 0 ? (
                                          <>
                                            <div className="parallax-buttons">
                                              {/* <a className="btn d-btn" id="brand-btn" href="/movie-details/18933">VIEW NOW</a> */}
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <Button
                                              type="button"
                                              className="d-brand-btn"
                                              target="_blank"
                                              href={item.externalLink}
                                              variant="primary next action-button"
                                              style={{ color: "#fff" }}
                                            >
                                              VIEW NOW
                                            </Button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>

                {/* <div className='world-event-stock-border'>
                           <div className="world-event-stock">
                              <div className="set-section-stock">
                                 <img onClick={this.handleShow}  src={require("../../assets/images/IMAGE (1).png")} alt="img" className='world-stock-img'></img>
                                 
                                 <p className='secure-connect'>Secure Your Spot NOW!</p>
                              </div>
                              <div className="set-section-stock1">
                                 <img onClick={this.handleShow}  src={require("../../assets/images/IMAGE-1.png")} alt="img" className='world-stock-img'></img>
                                 <p className='secure-connect'>Pre-Register NOW!</p>
                              </div>
                              <div className="set-section-stock2">
                                 <img onClick={this.handleShow}  src={require("../../assets/images/IMAGE-2.png")} alt="img" className='world-stock-img'></img>
                                 <p className='secure-connect'>Sign-Up NOW!</p>
                              </div>
                           </div>
                        </div> */}
                <hr className="set-gradient-border" id="border-set"></hr>
              </>
            ) : (
              ""
            )}

            {/* {this.state.podcastMap?.length > 0 ?
                     <Podcast id={16} resultMap={this.state.podcastMap} tagName="PODCASTS" viewAllUrl={'/view-podcast'} />
                     : ''} */}

            {this.state.originalSeriesMap?.length > 0 ? (
              <SeriesList
                seriesMap={this.state.originalSeriesMap}
                id={2}
                tagName="Original Series"
                playNowUrl="/series-details"
                viewAllUrl=""
              />
            ) : (
              ""
            )}

            <div className="d-veuitbgimg">
              {this.state.channelCategoryMap?.length > 4 ? (
                <>
                  {this.state.channelCategoryMap?.map((item, key) => {
                    let number = key + 1;
                    if (key >= 5) {
                      return (
                        <div key={key}>
                          {number % 2 === 0 ? (
                            <Musicartistlist
                              key={key}
                              resultMap={item.channelCategoryMap}
                              id={item.channelCategoryId}
                              tagName={item.channelCategoryName}
                              viewAllUrl={`/view-all/${item.channelCategoryId}`}
                            />
                          ) : (
                            <InfluencerList
                              key={key}
                              resultMap={item.channelCategoryMap}
                              id={item.channelCategoryId}
                              tagName={item.channelCategoryName}
                              viewAllUrl={`/view-all/${item.channelCategoryId}`}
                            />
                          )}
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                </>
              ) : (
                ""
              )}

              {/* {this.state.newReleaseMap.length > 0 ?
                        <MovieList moviesMap={this.state.newReleaseMap} checkingUpdate={this.state.checkingUpdate}
                           id={15} tagName="NFT MARKETPLACE" playNowUrl='/' viewAllUrl='' />
                        : ''} */}
            </div>

            {this.state.tvChannelsMap?.length > 0 ? (
              <TvChannelList
                dataMap={this.state.tvChannelsMap}
                id={8}
                tagName="TV Channel"
                viewAllUrl="/view-all/tv-channel"
              />
            ) : (
              ""
            )}

            {this.state.featuredChannelsMap?.length > 0 ? (
              <FeaturedChannelList
                dataMap={this.state.featuredChannelsMap}
                id={7}
                tagName="Featured channels"
                playNowUrl=""
                viewAllUrl=""
              />
            ) : (
              ""
            )}

            <section
              id="parallex"
              className="d-parallax-window parallax-window"
            >
              <div
                className="d-flex align-items-center justify-content-between"
                id="d-series-network"
              >
                <h3 className="main-title text-uppercase d-brand-network">
                  WORLD EDUCATION INITIATIVE
                </h3>
              </div>

              <div className="education-block">
                <hr className="set-gradient-border"></hr>
                <div>
                  {/* <div className='education-block-img' style={{ backgroundImage: `url(${WEI})`, backgroundRepeat: 'no-repeat' }}> */}
                  <div className="education-block-img">
                    <video
                      playsInline
                      autoPlay
                      loop
                      muted
                      className="education-block-img"
                      style={{ position: "absolute" }}
                    >
                      <source
                        src={"https://d1oqx62zb80kuc.cloudfront.net/10222.mp4"}
                        type="video/mp4"
                      />
                    </video>
                    <div className="inner-education-block-img"></div>
                    <video
                      playsInline
                      autoPlay
                      loop
                      muted
                      className="inner-education-block-vid"
                      style={{ position: "absolute" }}
                    >
                      <source
                        src={"https://d1oqx62zb80kuc.cloudfront.net/10222.mp4"}
                        type="video/mp4"
                      />
                    </video>
                    <div className="h-100">
                      <Row className="align-items-center justify-content-center h-100 parallaxt-details m-0">
                        <div className="r-mb-23 col">
                          <div className="text-center d-banner-space">
                            {/* <h1 className="slider-text text-uppercase">WORLD EDUCATION INITIATIVE</h1> */}
                            {/* <p className="slider-text WEI-txt">The best K-12 education on the planet
                                             For every child on the planet
                                             For FREE!</p> */}
                            <div
                              className="parallax-buttons"
                              id="set-donate-btn"
                            >
                              <Link
                                className="btn d-btn"
                                to="/#"
                                onClick={this.handleWorldEducationShow}
                                id="home-edubtn"
                              >
                                VIEW NOW
                              </Link>{" "}
                              {/* href="/" */}
                              {/* <Link className="btn d-btn" to="/#" id='donate-now'>DONATE NOW</Link> href="/" */}
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                    <hr id="set-gradient-border"></hr>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
