import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom'
import { secondsToHourse } from "../../helpers/core.helper";
import AppConfig from '../../config/app.config';

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})



export class Movie extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            movieObject: props.movieObject,
            key: props.indexKey,
            tagName: props.tagName,
            playNowUrl: props.playNowUrl,
            id: props.id,
            movieImage: AppConfig.API.MANU_IMAGE + props.id + "&random=" + Date.now(),
            checkingUpdate: props.checkingUpdate,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.movieObject !== prevProps.movieObject) {
            this.setState({ checkingUpdate: !this.state.checkingUpdate, movieObject: this.props.movieObject });
        }
    }

    addToWatchList = () => {
        this.props.addToWatchList(this.state.movieObject.movieId, this.state.movieObject.movieWatchListId);
    }

    render() {
        return (

            <div className=" block-images position-relative">

                <div className="img-box">
                    <img src={this.state.movieImage} className="img-fluid d-veuit-img" alt="" />
                </div>
                <div className="block-description block-social-info">
                    <h6 className="iq-title">{this.state.movieObject.movieName} </h6>
                    <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                        {this.state.movieObject.rating !== null && this.state.movieObject.rating !== '' ?
                            <div className="badge badge-secondary p-1 mr-2">{this.state.movieObject.rating}</div>
                            : ''}
                        {this.state.movieObject.movieLength !== undefined && this.state.movieObject.movieLength !== null && this.state.movieObject.movieLength !== '' ?
                            <span className="text-white"> {secondsToHourse(this.state.movieObject.movieLength)}  </span> : ""}
                    </div>

                    <div className="hover-buttons">
                        <Link to={`${this.state.playNowUrl}/${this.state.id}`} role="button" className="btn iq-button" id="d-play-btn">
                            <i className="fa fa-play mr-1" aria-hidden="true"></i>
                            Play Now
                        </Link>
                    </div>
                </div>

                <div className="block-social-info" style={{ height: '50px' }}>
                    <ul className="list-inline p-0 m-0 music-play-lists">
                        <li onClick={this.addToWatchList}><span style={{ background: this.state.movieObject.movieWatchListId !== -1 ? '#40bbfe' : '#ffffff', color: this.state.movieObject.movieWatchListId !== -1 ? '#ffffff' : '#40bbfe' }}><i className="ri-add-line"></i></span></li>
                    </ul>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Movie)