import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactJWPlayer from "react-jw-player";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  browserName,
  fullBrowserVersion,
  osVersion,
  osName,
  getUA,
} from "react-device-detect";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import { setSigninRedirectPath } from "../../store/home/home.actions";
import { selectEmployeeData } from "../../store/home/home.selectors";

var timestamp = Date.now();
var reltime = 0;
var logMap = [];
var networkInfo = {};
var displayInfo = {};
var audioInfo = {};
var secondTime = "";

var logStartTime = null;
var logEndTime = null;

const mapStateToProps = (state) => {
  return { employeeData: selectEmployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setSigninRedirectPath,
    },
    dispatch
  ),
});

export class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: "",
      expiry: "",
      videoLogData: [],

      videoObject: null,
      videoUrl: null,
      videoTitle: null,
      videoSubtitle: null,
      isMovie: null,
      projectLogId: null,

      isAutoPlay: true,
      isMute: false,
      isFullScreen: false,

      isVideoPaused: false,
      isHeaderVisible: true,
    };
    this.playPauseLogInterval = null;
    this.getInterval = null;
    this.getInterval = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId != null
    ) {
      if (this.props && this.props.videoObject) {
        logStartTime = parseInt(this.props.videoObject.startTime);
        logEndTime = parseInt(this.props.videoObject.endTime);
        this.setState({
          isMovie: this.props.isMovie,
          videoObject: this.props.videoObject,
          videoTitle: this.props.videoTitle,
          videoSubtitle: this.props.videoSubtitle,
          videoUrl:
            this.props.isMovie === 1
              ? this.props.videoObject.movieUrl
              : this.props.videoObject.episodeUrl,
          projectLogId: this.props.videoObject.videoLogId,
        });
      } else {
        this.props.history.replace("/");
      }

      this.createSessionForLog();
      this.getInterval = setInterval(() => this.getCustomerLogDetail(), 1000);
      this.setInterval = setInterval(() => this.saveCustomerLogDetail(), 60000);
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      });
      const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL + window.location.pathname);
      const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
    }
  }

  componentWillUnmount() {
    clearInterval(this.getInterval);
    clearInterval(this.setInterval);
    clearInterval(this.playPauseLogInterval);
  }

  createSessionForLog = () => {
    const payload = {
      device: {
        hardware: {
          type: "",
        },
        software: {
          application: {
            name: browserName,
            version: fullBrowserVersion,
          },
          os: {
            name: osName,
            version: osVersion,
          },
          browser: {
            name: browserName,
            version: fullBrowserVersion,
            userAgent: getUA,
          },
          player: {
            name: "ReactJWPlayer",
            version: "v8.25.6",
          },
        },
      },
    };
    const headers = {
      "Content-Type": "application/json",
      "X-APP-ID": AppConfig.API.CA_APP_ID,
    };

    UserService.createSessionForLog(payload, headers)
      .then((res) => {
        if (res.error != null) {
          console.log("data not found.  ");
        } else {
          if (res["success"]) {
            const index = res.data.log.findIndex((x) => x.psc === "AVC_LOG");
            if (index !== -1) {
              const logData = res.data.log[index].data;
              //res.data.log[index].data
              this.setState(
                {
                  apiKey: res.data.api_key.value,
                  expiry: res.data.api_key.expiry,
                  videoLogData: logData,
                },
                () => {
                  this.getDeviceInfo();
                }
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDeviceInfo = () => {
    const indexDevice = this.state.videoLogData.findIndex(
      (x) => x.point_code === "AVC_DEVICE"
    );
    if (indexDevice !== -1) {
      reltime = logMap.length === 0 ? 0 : Date.now() - timestamp;
      timestamp = Date.now();
      const diviceObject = {
        pId: this.state.videoLogData[indexDevice]._id,
        sequence: logMap.length,
        timestamp: timestamp,
        reltime: reltime,
        body: {
          hardware: {
            classTypeId: "",
            manufacturer: "",
            modelName: null,
          },
          software: {
            application: {
              name: browserName,
              version: fullBrowserVersion,
            },
            operatingSystem: {
              name: osName,
              version: osVersion,
            },
            middleware: {
              name: browserName,
              version: fullBrowserVersion,
              userAgent: getUA,
            },
            browser: {
              name: browserName,
              version: fullBrowserVersion,
              userAgent: getUA,
            },
            player: {
              name: "ReactJWPlayer",
              version: "v8.25.6",
            },
          },
        },
      };
      logMap.push(diviceObject);
    }
  };

  getCustomerLogDetail = () => {
    const indexNetwork =
      this.state.videoLogData !== null
        ? this.state.videoLogData.findIndex(
          (x) => x.point_code === "AVC_NETWORK"
        )
        : -1;
    if (indexNetwork !== -1) {
      reltime = logMap.length === 0 ? 0 : Date.now() - timestamp;
      timestamp = Date.now();
      const networkObject = {
        pId: this.state.videoLogData[indexNetwork]._id,
        sequence: logMap.length,
        timestamp: timestamp,
        reltime: reltime,
        body: {
          connected: navigator.onLine,
          downlink: navigator.connection.downlink,
          effectiveType: navigator.connection.effectiveType,
        },
      };
      if (JSON.stringify(networkInfo) !== JSON.stringify(networkObject.body)) {
        logMap.push(networkObject);
        networkInfo = networkObject.body;
      } else {
        networkInfo = networkObject.body;
      }
    }

    const indexDisplay =
      this.state.videoLogData !== null
        ? this.state.videoLogData.findIndex(
          (x) => x.point_code === "AVC_DISPLAY"
        )
        : -1;
    if (indexDisplay !== -1) {
      var playerHeight = "";
      var playerWidth = "";
      if (window.jwplayer !== undefined && window.jwplayer !== null) {
        playerHeight = window.jwplayer.defaults.height;
        playerWidth = window.jwplayer.defaults.width;
      }
      reltime = logMap.length === 0 ? 0 : Date.now() - timestamp;
      timestamp = Date.now();
      const displayObject = {
        pId: this.state.videoLogData[indexDisplay]._id,
        sequence: logMap.length,
        timestamp: timestamp,
        reltime: reltime,
        body: {
          connected: navigator.onLine,
          display: {
            height: window.innerHeight,
            width: window.innerWidth,
          },
          player: {
            height: playerHeight,
            width: playerWidth,
          },
        },
      };
      if (JSON.stringify(displayInfo) !== JSON.stringify(displayObject.body)) {
        logMap.push(displayObject);
        displayInfo = displayObject.body;
      } else {
        displayInfo = displayObject.body;
      }
    }

    const indexAudio =
      this.state.videoLogData !== null
        ? this.state.videoLogData.findIndex((x) => x.point_code === "AVC_AUDIO")
        : -1;
    if (indexAudio !== -1) {
      var volume = 0;
      const localStorage = window.localStorage;
      if (localStorage !== undefined && localStorage !== null) {
        volume = localStorage["jwplayer.volume"];
      }
      reltime = logMap.length === 0 ? 0 : Date.now() - timestamp;
      timestamp = Date.now();
      const audioObject = {
        pId: this.state.videoLogData[indexAudio]._id,
        sequence: logMap.length,
        timestamp: timestamp,
        reltime: reltime,
        body: {
          volume: volume,
          isMute: this.state.isMute,
        },
      };
      if (JSON.stringify(audioInfo) !== JSON.stringify(audioObject.body)) {
        logMap.push(audioObject);
        audioInfo = audioObject.body;
      } else {
        audioInfo = audioObject.body;
      }
    }
  };

  saveCustomerLogDetail = () => {
    const logData = logMap;
    if (logMap.length > 0) {
      logMap = [];
      const payload = {
        clientPublishTimestamp: Date.now(),
        clientPublishRelativeTime: "60000",
        events: logData,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: this.state.apiKey,
      };

      UserService.createCustomerLog(payload, headers)
        .then((res) => {
          if (res.error != null) {
            console.log("data not found.  ");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getInteractionInfo = (eventData, isPlayBack) => {
    const indexDevice = this.state.videoLogData.findIndex(
      (x) => x.point_code === "AVC_INTERACTION"
    );
    if (indexDevice !== -1) {
      reltime = logMap.length === 0 ? 0 : Date.now() - timestamp;
      timestamp = Date.now();
      const object = {
        pId: this.state.videoLogData[indexDevice]._id,
        sequence: logMap.length,
        timestamp: timestamp,
        reltime: reltime,
        body: eventData,
      };
      logMap.push(object);
    }
    if (isPlayBack === 1) {
      this.getPlaybackInfo(eventData.type);
    }
  };

  getPlaybackInfo = (type) => {
    const indexDevice = this.state.videoLogData.findIndex(
      (x) => x.point_code === "AVC_PLAYBACK"
    );
    if (indexDevice !== -1) {
      reltime = logMap.length === 0 ? 0 : Date.now() - timestamp;
      timestamp = Date.now();
      const player = window.jwplayer("customVideo");
      const qualityLevel = player.getQualityLevels();
      const indexQuality = player.getCurrentQuality();
      const object = {
        pId: this.state.videoLogData[indexDevice]._id,
        sequence: logMap.length,
        timestamp: timestamp,
        reltime: reltime,
        body: {
          action: type,
          currentTime: player.getCurrentTime(),
          playbackRate: player.getPlaybackRate(),
          stretching: player.getStretching(),
          buffers: player.getBuffer(),
          qualityLevels: qualityLevel,
          currentQuality: qualityLevel[indexQuality].label,
        },
      };
      logMap.push(object);
    }
  };

  onReady(event) {
    this.playPauseLogInterval = setInterval(() => {
      const player = window.jwplayer("customVideo");
      const config = player.getConfig();
      this.createPlayPauseLog("3", logEndTime, Math.floor(config.currentTime));
    }, 30000);
    this.createPlayPauseLog(
      logEndTime === 0 ? "1" : "3",
      logStartTime,
      logEndTime
    );

    const player = window.jwplayer("customVideo");
    if (logEndTime !== null) {
      player.seek(logEndTime);
    }

    const onReady = {
      type: event.type,
      newstate: "",
      oldstate: "",
      reason: "",
      viewable: event.viewable,
      setupTime: event.setupTime,
      fullscreen: this.state.isFullScreen,
    };
    this.getInteractionInfo(onReady, 0);
  }
  onVideoLoad(event) {
    const onVideoLoad = {
      type: event.type,
      newstate: "",
      oldstate: "",
      reason: "VideoLoad",
      viewable: "1",
      setupTime: "",
      fullscreen: this.state.isFullScreen,
    };
    this.getInteractionInfo(onVideoLoad, 0);
  }
  onAutoStart(event) {
    const onAutoStart = {
      type: event.type,
      newstate: event.newstate,
      oldstate: event.oldstate,
      reason: event.reason,
      viewable: event.viewable,
      setupTime: "",
      fullscreen: this.state.isFullScreen,
    };
    this.getInteractionInfo(onAutoStart, 0);
  }
  onEnterFullScreen(event) {
    this.setState({ isFullScreen: event.fullscreen });
    const onEnterFullScreen = {
      type: event.type,
      newstate: "",
      oldstate: "",
      reason: "Interaction",
      viewable: "1",
      setupTime: "",
      fullscreen: event.fullscreen,
    };
    this.getInteractionInfo(onEnterFullScreen, 1);
  }
  onExitFullScreen(event) {
    this.setState({ isFullScreen: event.fullscreen });
    const onExitFullScreen = {
      type: event.type,
      newstate: "",
      oldstate: "",
      reason: "Interaction",
      viewable: "1",
      setupTime: "",
      fullscreen: event.fullscreen,
    };
    this.getInteractionInfo(onExitFullScreen, 1);
  }
  onError(event) {
    //console.log("--------------------onError")
    //console.log(event);
  }
  onSetupError(event) { }
  onPlay(event) {
    const onPlay = {
      type: event.type,
      newstate: event.newstate,
      oldstate: event.oldstate,
      reason: event.reason,
      viewable: event.viewable,
      setupTime: "",
      fullscreen: this.state.isFullScreen,
    };
    this.getInteractionInfo(onPlay, 1);
  }
  onPause(event) {
    clearInterval(this.playPauseLogInterval);
    this.playPauseLogInterval = null;
    const player = window.jwplayer("customVideo");
    const config = player.getConfig();
    this.createPlayPauseLog("2", logEndTime, Math.floor(config.currentTime));

    const onPause = {
      type: event.type,
      newstate: event.newstate,
      oldstate: event.oldstate,
      reason: event.reason,
      viewable: event.viewable,
      setupTime: "",
      fullscreen: this.state.isFullScreen,
    };
    this.getInteractionInfo(onPause, 1);
  }
  onResume(event) {
    clearInterval(this.playPauseLogInterval);
    this.playPauseLogInterval = null;
    this.playPauseLogInterval = setInterval(() => {
      const player = window.jwplayer("customVideo");
      const config = player.getConfig();
      this.createPlayPauseLog("3", logEndTime, Math.floor(config.currentTime));
    }, 30000);
    const player = window.jwplayer("customVideo");
    const config = player.getConfig();
    this.createPlayPauseLog("3", logStartTime, Math.floor(config.currentTime));

    const onResume = {
      type: event.type,
      newstate: event.newstate,
      oldstate: event.oldstate,
      reason: event.reason,
      viewable: event.viewable,
      setupTime: "",
      fullscreen: this.state.isFullScreen,
    };
    this.getInteractionInfo(onResume, 1);
  }
  onMute(event) {
    this.setState({ isMute: true });
  }
  onUnmute(event) {
    this.setState({ isMute: false });
  }
  onComplete(event) {
    const player = window.jwplayer("customVideo");
    const config = player.getConfig();
    this.createPlayPauseLog("4", logEndTime, Math.floor(config.currentTime));
  }
  onTime(event) {
    const indexDevice = this.state.videoLogData.findIndex(
      (x) => x.point_code === "AVC_ACQUISITION"
    );
    if (indexDevice !== -1) {
      const player = window.jwplayer("customVideo");
      const config = player.getConfig();

      if (Math.floor(secondTime) !== Math.floor(config.currentTime)) {
        secondTime = Math.floor(config.currentTime);

        reltime = logMap.length === 0 ? 0 : Date.now() - timestamp;
        timestamp = Date.now();
        const object = {
          pId: this.state.videoLogData[indexDevice]._id,
          sequence: logMap.length,
          timestamp: timestamp,
          reltime: reltime,
          body: {
            bitrate: config.bitrateSelection,
            buffer: config.buffer,
            currentTime: config.currentTime,
            duration: config.duration,
            bandwidthEstimate: config.bandwidthEstimate,
            base: config.base,
            iFrame: config.iFrame,
            provider: config.provider,
            setupConfig: {
              //file: config.setupConfig.file,
              aspectratio: config.setupConfig.aspectratio,
              autostart: config.setupConfig.autostart,
            },
            streamType: config.streamType,
            plugins: config.plugins,
            castState: config.castState,
          },
        };
        logMap.push(object);
      }
    }
  }
  onOneHundredPercent(event) { }

  createPlayPauseLog = (movieLogPhaseType, startTime, endTime) => {
    logStartTime = startTime;
    logEndTime = endTime;
    const payload = {
      loginEmployeeId: this.props.employeeData?.employeeId,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      isMovie: this.state.isMovie,
      movieLogPhaseType: movieLogPhaseType,
      startTime: startTime,
      endTime: endTime,
      contractId: this.state.videoObject.contractId,
    };
    if (this.state.isMovie === 1) {
      payload["movieId"] = this.state.videoObject.movieId;
      payload["movieName"] = this.state.videoObject.movieName;
    } else if (this.state.isMovie === 0) {
      payload["episodeId"] = this.state.videoObject.episodeId;
      payload["episodeName"] = this.state.videoObject.episodeName;
    }

    if (this.state.projectLogId !== -1) {
      payload["projectLogId"] = this.state.projectLogId;
    }
    // console.log("PLAYPAUSELOG", payload.movieLogPhaseType, payload.startTime, payload.endTime, payload.projectLogId);
    UserService.savePlayPauseResumeLogs(payload)
      .then((res) => {
        if (res["status"]) {
          // console.log("res log", res['data']);
          if (this.state.projectLogId === -1) {
            this.setState({ projectLogId: res["data"].projectLogId });
          }
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  render() {
    return (
      <section
        className="height-100-vh video-wrapper"
        style={{ padding: "0 !important" }}
        id="video-section"
      >
        {this.state.videoObject && (
          <>
            {this.state.isHeaderVisible && (
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "#00000099",
                  position: "fixed",
                  top: 0,
                  zIndex: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0 50px",
                }}
                className="video-mobile"
              >
                <div className="video-mobile-back">
                  <ArrowBackIcon
                    className="video-back"
                    style={{ height: "35px", width: "35px", cursor: "pointer" }}
                    onClick={() => {
                      const player = window.jwplayer("customVideo");
                      const config = player.getConfig();
                      this.props.goBack(
                        logEndTime,
                        Math.floor(config.currentTime),
                        this.state.projectLogId
                      );
                    }}
                  />

                  <div className="video-movies">
                    <p className="video-series-title">
                      {this.state.videoTitle}
                    </p>
                    {this.state.videoSubtitle != null && (
                      <p className="video-series-episode">
                        {this.state.videoSubtitle}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <ReactJWPlayer
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              playerId="customVideo"
              playerScript="https://cdn.jwplayer.com/libraries/zFFedrqG.js"
              file={`https://dhg1q0hnawod0.cloudfront.net/${this.state.videoUrl}.m3u8`}
              //file={`http://files.veuit.com/${this.state.videoUrl}.m3u8`}
              isAutoPlay={this.state.isAutoPlay}
              // aspectRatio="20:9"
              customProps={{
                playbackRateControls: [1, 1.25, 1.5],
                cast: {},
                fit: "fill",
              }}
              onUserActive={() => {
                this.setState({
                  isHeaderVisible: true,
                });
              }}
              onUserInactive={() => {
                this.setState({
                  isHeaderVisible: this.state.isVideoPaused ? true : false,
                });
              }}
              onReady={this.onReady.bind(this)}
              onVideoLoad={this.onVideoLoad.bind(this)}
              onAutoStart={this.onAutoStart.bind(this)}
              onEnterFullScreen={this.onEnterFullScreen.bind(this)}
              onExitFullScreen={this.onExitFullScreen.bind(this)}
              onError={this.onError.bind(this)}
              onSetupError={this.onSetupError.bind(this)}
              onPlay={this.onPlay.bind(this)}
              onPause={(event) => {
                this.setState({
                  isVideoPaused: true,
                  isHeaderVisible: true,
                });
                this.onPause(event);
              }}
              onResume={(event) => {
                this.setState({
                  isVideoPaused: false,
                });
                this.onResume(event);
              }}
              onMute={this.onMute.bind(this)}
              onUnmute={this.onUnmute.bind(this)}
              onComplete={this.onComplete.bind(this)}
              onTime={this.onTime.bind(this)}
              onOneHundredPercent={this.onOneHundredPercent.bind(this)}
            />
          </>
        )}
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);
