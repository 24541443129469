const HomeActionTypes = {
  HOME_SYNC_START: "HOME_SYNC_START",
  HOME_SYNC_FINISH: "HOME_SYNC_FINISH",
  TAB_LIST_SYNC_START: "TAB_LIST_SYNC_START",
  TAB_LIST_SYNC_FINISH: "TAB_LIST_SYNC_FINISH",
  NEW_RELEASE_SYNC_START: "NEW_RELEASE_SYNC_START",
  NEW_RELEASE_SYNC_FINISH: "NEW_RELEASE_SYNC_FINISH",
  CLASSIC_SYNC_START: "CLASSIC_SYNC_START",
  CLASSIC_SYNC_FINISH: "CLASSIC_SYNC_FINISH",
  INDEPENDENT_SYNC_START: "INDEPENDENT_SYNC_START",
  INDEPENDENT_SYNC_FINISH: "INDEPENDENT_SYNC_FINISH",
  ORIGINAL_SERIES_SYNC_START: "ORIGINAL_SERIES_SYNC_START",
  ORIGINAL_SERIES_SYNC_FINISH: "ORIGINAL_SERIES_SYNC_FINISH",
  INFLUENCER_SYNC_START: "INFLUENCER_SYNC_START",
  INFLUENCER_SYNC_FINISH: "INFLUENCER_SYNC_FINISH",
  COMEDY_SYNC_START: "COMEDY_SYNC_START",
  COMEDY_SYNC_FINISH: "COMEDY_SYNC_FINISH",
  MUSIC_SYNC_START: "MUSIC_SYNC_START",
  MUSIC_SYNC_FINISH: "MUSIC_SYNC_FINISH",
  BRANDS_SYNC_START: "BRANDS_SYNC_START",
  BRANDS_SYNC_FINISH: "BRANDS_SYNC_FINISH",
  BRANDS_PARTNERS_SYNC_START: "BRANDS_PARTNERS_SYNC_START",
  BRANDS_PARTNERS_SYNC_FINISH: "BRANDS_PARTNERS_SYNC_FINISH",
  EXCLUSIVE_EVENT_SYNC_START: "EXCLUSIVE_EVENT_SYNC_START",
  EXCLUSIVE_EVENT_SYNC_FINISH: "EXCLUSIVE_EVENT_SYNC_FINISH",
  FEATURED_CHANNELS_SYNC_START: "FEATURED_CHANNELS_SYNC_START",
  FEATURED_CHANNELS_SYNC_FINISH: "FEATURED_CHANNELS_SYNC_FINISH",
  TV_CHANNELS_SYNC_START: "TV_CHANNELS_SYNC_START",
  TV_CHANNELS_SYNC_FINISH: "TV_CHANNELS_SYNC_FINISH",
  PODCAST_SYNC_START: "PODCAST_SYNC_START",
  PODCAST_SYNC_FINISH: "PODCAST_SYNC_FINISH",
  TRENDING_LIVES_SYNC_START: "TRENDING_LIVES_SYNC_START",
  TRENDING_LIVES_SYNC_FINISH: "TRENDING_LIVES_SYNC_FINISH",
  ATHLETES_SYNC_START: "ATHLETES_SYNC_START",
  ATHLETES_SYNC_FINISH: "ATHLETES_SYNC_FINISH",
  FINE_ARTISTS_SYNC_START: "FINE_ARTISTS_SYNC_START",
  FINE_ARTISTS_SYNC_FINISH: "FINE_ARTISTS_SYNC_FINISH",
  CHANNEL_CATEGORY_SYNC_START: "CHANNEL_CATEGORY_SYNC_START",
  CHANNEL_CATEGORY_SYNC_FINISH: "CHANNEL_CATEGORY_SYNC_FINISH",
  SET_LOCATION: "SET_LOCATION",
  SET_EMPLOYEE_DATA: "SET_EMPLOYEE_DATA",
  SET_SIGNIN_REDIRECT_PATH: "SET_SIGNIN_REDIRECT_PATH",
  FEATURED_SYNC_START: "FEATURED_SYNC_START",
  FEATURED_SYNC_FINISH: "FEATURED_SYNC_FINISH",
};

export default HomeActionTypes;
