import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import success from '../../assets/images/success.png'
import UserService from '../../services/user/user.service';
import Backdrop from '@material-ui/core/Backdrop';

const mapStateToProps = (state) => {
    return {

    };
}


const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {

        },
        dispatch
    )
})


export class Success extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: 0,
            subscriptionId: '',
            domain: '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const pathArray = window.location.pathname.split('/');
        if (pathArray[3] !== null && pathArray[3] !== '') {
            this.setState({ isLoading: 1 });
            //this.validateSubscriptionId(pathArray[3]);
            this.setState({ subscriptionId: pathArray[3], isLoading: 0 });
        } else {
            this.props.history.goBack();
        }
    }

    validateSubscriptionId = (subscriptionId) => {
        const payload = {
            subscriptionId: subscriptionId
        }
        UserService.validateSubscriptionId(payload).then(res => {
            if (res.error != null) {
                this.setState({ isLoading: 0, subscriptionId: '' });
            } else {
                if (res['status']) {
                    this.setState({ subscriptionId: subscriptionId, domain: res['data'].domain, isLoading: 0 });
                } else {
                    this.setState({ isLoading: 0, subscriptionId: '' });
                }
            }
        }).catch(err => {
            this.setState({ isLoading: 0, subscriptionId: '' });

        })
    }


    render() {
        return (
            <section className="sign-in-page">
                {this.state.isLoading === 1 ?
                    <Backdrop style={{ zIndex: 100, color: '#003699' }} open>
                        <img src={require('../../assets/images/Veuit-Spinner.gif')} style={{ textAlign: 'center', display: 'block', justifyContent: 'center', alignItems: 'center', margin: 'auto' }} className="c-logo" alt="streamit" />
                    </Backdrop>
                    :
                    <Container>
                        <Row className="justify-content-center align-items-center height-self-center">
                            <Col lg="5" md="12" className="align-self-center">

                                <div className="sign-user_card ">
                                    <div className="sign-in-page-data text-center">
                                        {this.state.subscriptionId !== '' ?
                                            <div className="sign-in-from w-100 m-auto">
                                                <img src={success} width="80" alt="" />
                                                <h3 className="mt-3 mb-0 text-success"><strong>Success ! </strong></h3>
                                                <br />
                                                <p className="text-white">Thank you for registering with Veuit channel. Your account will be activated within 24 hours. Kindly refer to your email for further details.</p>
                                                {/* Your doamin is {this.state.domain}. */}
                                                <div className="d-inline-block w-100">
                                                    <Link to="/" className="btn btn-primary mt-3">Back to Home</Link>
                                                </div>
                                            </div>
                                            :
                                            <div className="sign-in-from w-100 m-auto">
                                                <h5>Oops! <br /> Something went wrong. Please try again.</h5>
                                                <div className="d-inline-block w-100">
                                                    <Link to="/" className="btn btn-primary mt-3">Back to Home</Link>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                }
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Success)