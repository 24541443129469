import React from "react";
import { Form, Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import Backdrop from "@material-ui/core/Backdrop";
import { ReactComponent as TooltipArrowsmall } from "../../assets/images/tooltip-arrowsmall.svg";

const numberValidation = new RegExp(/^\+?[0-9]*$/);
const removeExtraSpace = (s) => s.replace(/\s/g, "");

class PaymentAddAddressModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      isAddAddressModalVisible: false,
      employeeId: this.props.employeeId,
      countryId: -1,
      countryMap: [],
      stateId: -1,
      stateMap: [],
      addressId: null,
      name: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      zipCode: "",
      isDefault: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeId != null) {
      const addressObj = this.props.addressObject;
      this.setState({
        employeeId: this.props.employeeId,
        countryId: addressObj != null ? addressObj.countryId : -1,
        stateId: addressObj != null ? addressObj.stateId : -1,
        addressId: addressObj != null ? addressObj.addressId : null,
        name: addressObj != null ? addressObj.name : "",
        phone: addressObj != null ? addressObj.phone : "",
        address1: addressObj != null ? addressObj.address1 : "",
        address2: addressObj != null ? addressObj.address2 : "",
        city: addressObj != null ? addressObj.city : "",
        zipCode: addressObj != null ? addressObj.zipCode : "",
        isDefault: addressObj != null ? addressObj.isDefault : 0,
      });
      if (addressObj != null) {
        this.getStateList(addressObj.countryId);
      }
      this.getCountryList();
    } else {
      this.props.history.goBack();
    }
  }

  getCountryList = () => {
    this.setState({
      isLoading: 1,
    });
    UserService.getCountryList(
      AppConfig.API.SUBSCRIPTION_ID,
      AppConfig.API.VERTICAL_ID
    )
      .then((res) => {
        if (res.error != null) {
          this.setState({
            countryMap: null,
            isLoading: 0,
          });
        } else {
          if (res["status"] && res["data"] != null && res["data"].length >= 0) {
            this.setState({
              countryMap: res["data"],
              isLoading: 0,
            });
          } else {
            this.setState({
              countryMap: null,
              isLoading: 0,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          countryMap: null,
          isLoading: 0,
        });
      });
  };

  updateState = (event) => {
    var stateId = parseInt(event.target.value);
    this.setState({ stateId: stateId });
  };

  getStateList = (countryId) => {
    this.setState({ countryId: countryId, isLoading: 1 });
    UserService.getStateList(countryId)
      .then((res) => {
        if (res.error != null) {
          this.setState({
            stateMap: null,
            isLoading: 0,
          });
        } else {
          if (res["status"] && res["data"] != null && res["data"].length >= 0) {
            this.setState({
              stateMap: res["data"],
              isLoading: 0,
            });
          } else {
            this.setState({
              stateMap: null,
              isLoading: 0,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          stateMap: null,
          isLoading: 0,
        });
      });
  };

  saveAddress = () => {
    var pattern = new RegExp(/^\+?[0-9]*$/);
    const rule = new RegExp(/^[a-zA-Z ]{2,40}$/);
    if (this.state.countryId === null || this.state.countryId === -1) {
      NotificationManager.warning("Please select country.", "", 2000);
    } else if (this.state.name === "") {
      NotificationManager.warning("Please enter name.", "", 2000);
    } else if (!rule.test(this.state.name)) {
      NotificationManager.warning(
        "Name must contains at list two letter with only characters, not allow numaric value.",
        "",
        2000
      );
    } else if (this.state.phone === "") {
      NotificationManager.warning("Please enter phone.", "", 2000);
    } else if (!pattern.test(this.state.phone)) {
      NotificationManager.warning("Please enter valid phone.", "", 2000);
    } else if (this.state.phone.length < 10 || this.state.phone.length > 13) {
      NotificationManager.warning("Please enter valid phone.", "", 2000);
    } else if (this.state.address1 === "") {
      NotificationManager.warning("Please enter street address.", "", 2000);
    } else if (this.state.city === "") {
      NotificationManager.warning("Please enter city.", "", 2000);
    } else if (this.state.stateId === null || this.state.stateId === -1) {
      NotificationManager.warning("Please select state.", "", 2000);
    } else if (this.state.zipCode === "") {
      NotificationManager.warning("Please enter zipCode.", "", 2000);
    } else if (!pattern.test(this.state.zipCode)) {
      NotificationManager.warning("Please enter valid zipCode.", "", 2000);
    } else {
      this.setState({ isLoading: 1 });
      const payload = {
        employeeId: this.state.employeeId,
        appId: AppConfig.API.APP_ID,
        addressId: this.state.addressId,
        name: this.state.name,
        countryId: this.state.countryId,
        stateId: this.state.stateId,
        phone: this.state.phone,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        zipCode: this.state.zipCode,
        isDefault: this.state.isDefault,
      };

      UserService.addBillingAddress(payload)
        .then((res) => {
          if (res.error != null) {
            if (res.error?.response?.data?.message != null) {
              NotificationManager.error(
                res.error.response.data.message,
                "",
                3000
              );
              this.setState({
                isLoading: 0,
                countryId: -1,
                stateId: -1,
                addressId: null,
                name: "",
                phone: "",
                address1: "",
                address2: "",
                city: "",
                zipCode: "",
                isDefault: 0,
              });
            } else {
              NotificationManager.error(
                this.state.addressId != null
                  ? "Could not update address."
                  : "Could not add address.",
                "",
                3000
              );
              this.setState({
                isLoading: 0,
                countryId: -1,
                stateId: -1,
                addressId: null,
                name: "",
                phone: "",
                address1: "",
                address2: "",
                city: "",
                zipCode: "",
                isDefault: 0,
              });
            }
          } else {
            if (res["status"]) {
              NotificationManager.success(
                this.state.addressId != null
                  ? "Address updated successfully."
                  : "Address added successfully.",
                "",
                3000
              );
              this.setState({
                isLoading: 0,
                countryId: -1,
                stateId: -1,
                addressId: null,
                name: "",
                phone: "",
                address1: "",
                address2: "",
                city: "",
                zipCode: "",
                isDefault: 0,
              });
              this.props.hideModal();
            } else {
              NotificationManager.error(
                this.state.addressId != null
                  ? "Could not update address."
                  : "Could not add address.",
                "",
                3000
              );
              this.setState({
                isLoading: 0,
                countryId: -1,
                stateId: -1,
                addressId: null,
                name: "",
                phone: "",
                address1: "",
                address2: "",
                city: "",
                zipCode: "",
                isDefault: 0,
              });
            }
          }
        })
        .catch((err) => {
          NotificationManager.error(err, "", 3000);
          this.setState({
            isLoading: 0,
            countryId: -1,
            stateId: -1,
            addressId: null,
            name: "",
            phone: "",
            address1: "",
            address2: "",
            city: "",
            zipCode: "",
            isDefault: 0,
          });
        });
    }
  };

  closeModal = () => {
    this.setState({
      isLoading: 0,
      countryId: -1,
      stateId: -1,
      addressId: null,
      name: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      zipCode: "",
      isDefault: 0,
    });
    this.props.hideModal();
  };

  deleteBillingAddress = (addressId) => {
    this.setState({ isLoading: 1 });
    UserService.deleteBillingAddress(addressId)
      .then((res) => {
        if (res.error != null) {
          if (res.error?.response?.data?.message != null) {
            NotificationManager.error(
              res.error.response.data.message,
              "",
              3000
            );
            this.setState({
              isLoading: 0,
            });
          } else {
            NotificationManager.error("Could not delete address.", "", 3000);
            this.setState({
              isLoading: 0,
            });
          }
        } else {
          if (res["status"]) {
            NotificationManager.success(
              "Address deleted successfully.",
              "",
              3000
            );
            this.setState({
              isLoading: 0,
            });
            this.props.hideModal();
          } else {
            NotificationManager.error("Could not delete address.", "", 3000);
            this.setState({
              isLoading: 0,
            });
          }
        }
      })
      .catch((err) => {
        NotificationManager.error(err, "", 3000);
        this.setState({
          isLoading: 0,
        });
      });
  };

  render() {
    return (
      <Modal
        style={{ zIndex: 1500, minHeight: "100vh" }}
        show={this.props.show}
        onHide={this.closeModal}
        backdrop="static"
        keyboard={false}
        centered
        id="billing-address-modal"
      >
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          ""
        )}

        <div id="address-modal">
          <div className="customer-billing">
            <Modal.Header closeButton>
              <div>
                <h4>{this.props.name}</h4>
              </div>
            </Modal.Header>
          </div>
          <div className="new-address-field">
            <Form.Group>
              <Form.Label
                htmlFor="exampleFormControlSelect2"
                className="payment-form-label"
              >
                Country/Region
              </Form.Label>
              <select
                className="form-control new-payment-select"
                id="exampleFormControlSelectAdd"
                value={this.state.countryId}
                onChange={(e) => {
                  this.getStateList(parseInt(e.target.value));
                }}
              >
                <option value="-1" className="option-select">
                  Select your country
                </option>
                {this.state.countryMap != null &&
                  this.state.countryMap.map((item, key) => {
                    return (
                      <option
                        className="option-select"
                        value={item.key}
                        key={key}
                      >
                        {item.value}
                      </option>
                    );
                  })}
              </select>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label
                htmlFor="exampleFormControlSelect2"
                className="payment-form-label"
              >
                Full name (First and Last name){" "}
              </Form.Label>
              <Form.Control
                type="text"
                maxLength="40"
                name="name"
                className="payment-form-field"
                placeholder="Enter full name"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <div className="payment-phn-number">
                <Form.Label
                  htmlFor="exampleFormControlSelect2"
                  className="payment-form-label"
                >
                  Phone number
                </Form.Label>
                <OverlayTrigger
                  // show={true}
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      <TooltipArrowsmall className="right-arrow" />
                      <div id="button-tooltip1">
                        Your phone number may be used to assist delivery.
                      </div>
                    </Tooltip>
                  }
                >
                  <span>Why?</span>
                </OverlayTrigger>
              </div>
              <Form.Control
                type="text"
                name="phone"
                maxLength="13"
                className="payment-form-field"
                placeholder="Enter phone number"
                value={this.state.phone}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    numberValidation.test(e.target.value)
                  ) {
                    this.setState({ phone: removeExtraSpace(e.target.value) });
                  }
                }}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label
                htmlFor="exampleFormControlSelect2"
                className="payment-form-label"
              >
                Address
              </Form.Label>
              <Form.Control
                type="text"
                maxLength="40"
                name="name"
                className="payment-form-field"
                placeholder="Enter street address"
                value={this.state.address1}
                onChange={(e) => this.setState({ address1: e.target.value })}
              />
              <Form.Control
                type="text"
                maxLength="40"
                name="name"
                className="payment-form-field"
                id="payment-form-field"
                placeholder="Apt, suite, unit, building, floor, etc."
                value={this.state.address2}
                onChange={(e) => this.setState({ address2: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label
                htmlFor="exampleFormControlSelect2"
                className="payment-form-label"
              >
                City
              </Form.Label>
              <Form.Control
                type="text"
                maxLength="40"
                name="name"
                className="payment-form-field"
                placeholder="Enter city"
                value={this.state.city}
                onChange={(e) => this.setState({ city: e.target.value })}
              />
            </Form.Group>
            <div className="add-address-state">
              <Form.Group className="form-group1">
                <Form.Label
                  htmlFor="exampleFormControlSelect2"
                  className="payment-form-label"
                >
                  State
                </Form.Label>
                <select
                  className="form-control new-country-select"
                  id="exampleFormControlSelect"
                  value={this.state.stateId}
                  onChange={this.updateState.bind(this)}
                >
                  <option value="-1" className="option-select">
                    Select State
                  </option>
                  {this.state.stateMap != null &&
                    this.state.stateMap.map((item, key) => {
                      return (
                        <option
                          value={item.key}
                          key={key}
                          className="option-select"
                        >
                          {item.value}
                        </option>
                      );
                    })}
                </select>
              </Form.Group>
              <Form.Group className="form-group1">
                <Form.Label
                  htmlFor="exampleFormControlSelect2"
                  className="payment-form-label"
                >
                  Zip Code
                </Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  maxLength="6"
                  className="payment-zipcode"
                  placeholder="Enter zip code"
                  value={this.state.zipCode}
                  onChange={(e) => {
                    if (
                      e.target.value === "" ||
                      numberValidation.test(e.target.value)
                    ) {
                      this.setState({
                        zipCode: removeExtraSpace(e.target.value),
                      });
                    }
                  }}
                />
              </Form.Group>
            </div>
            <div className="setup-default-add">
              <h4>Make this my default billing address</h4>
              <Form.Check type={"radio"} id={`check-api-${"radio"}`}>
                {this.state.isDefault === 1 ? (
                  <Form.Check.Input
                    type={"radio"}
                    className="new-payment-radio-pay"
                    onClick={(e) => {
                      this.setState({ isDefault: 0 });
                    }}
                    checked={true}
                  />
                ) : (
                  <Form.Check.Input
                    type={"radio"}
                    className="new-payment-radio-pay"
                    onClick={(e) => {
                      this.setState({ isDefault: 1 });
                    }}
                    checked={false}
                  />
                )}
              </Form.Check>
            </div>
          </div>
          <div className="add-new-address-btn">
            {this.props.isEdit === 1 ? (
              <>
                <button className="update-btn" onClick={this.saveAddress}>
                  Update
                </button>
                <button
                  className="delete-btn"
                  onClick={this.deleteBillingAddress.bind(
                    this,
                    this.state.addressId
                  )}
                >
                  Delete
                </button>
              </>
            ) : (
              <button
                className="add-address-btn"
                id="add-address"
                onClick={this.saveAddress}
              >
                Add address
              </button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default PaymentAddAddressModal;
