import React from "react";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { secondsToHourse } from "../../helpers/core.helper";
import AppConfig from "../../config/app.config";

export class PurchaseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataMap: props.dataMap,
      tagName: props.tagName,
      id: props.id,
      viewAllUrl: props.viewAllUrl,
      prevId: "prev" + props.id,
      nextId: "next" + props.id,
    };
  }
  render() {
    return (
      <div className="shows-lists">
        <div className="shows-name">
          <h4>{this.state.tagName}</h4>
          <p>
            <Link to={this.state.viewAllUrl}>View More {">"} </Link>
          </p>
        </div>
        <div id="favorites-contens">
          <div
            id={this.state.prevId}
            className="swiper-button swiper-button-prev"
          >
            <i className="fa fa-chevron-left d-icon"></i>
          </div>
          <div
            id={this.state.nextId}
            className="swiper-button swiper-button-next"
          >
            <i className="fa fa-chevron-right d-icon"></i>
          </div>
          <Swiper
            navigation={{
              prevEl: `#${this.state.prevId}`,
              nextEl: `#${this.state.nextId}`,
            }}
            breakpoints={{
              1200: {
                slidesPerView: 6,
                spaceBetween: 26,
              },
              1054: {
                slidesPerView: 5.5,
                spaceBetween: 26,
              },
              900: {
                slidesPerView: 5,
                spaceBetween: 12,
              },
              800: {
                slidesPerView: 4.5,
                spaceBetween: 12,
              },
              700: {
                slidesPerView: 4,
                spaceBetween: 12,
              },
              600: {
                slidesPerView: 3.5,
                spaceBetween: 12,
              },
              577: {
                slidesPerView: 3,
                spaceBetween: 12,
              },
              576: {
                slidesPerView: 3.9,
                spaceBetween: 12,
              },
              500: {
                slidesPerView: 2.8,
                spaceBetween: 12,
              },

              460: {
                slidesPerView: 2.5,
                spaceBetween: 12,
              },

              400: {
                slidesPerView: 2.3,
                spaceBetween: 12,
              },
              360: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              320: {
                slidesPerView: 1.7,
                spaceBetween: 12,
              },
            }}
            loop={false}
            // slidesPerView={16}
            // spaceBetween={20}
            as="ul"
            className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
          >
            {this.state.dataMap.map((item, key) => (
              <SwiperSlide as="li" key={key} id="d-slider">
                <div className="show-listsub-section" key={`CLAS-${key}`}>
                  <Link to={`/movie-details/${item.movieId}`}>
                    <img
                      src={
                        AppConfig.API.MANU_IMAGE +
                        item.movieId +
                        "&random=" +
                        Date.now()
                      }
                      className="show-img-new"
                      alt="img"
                    />
                  </Link>
                  <div className="images-name">
                    <p>{item.movieName} </p>
                    <div className="image-tags" id="image-tags">
                      <div className="image-ownername">
                        {/* <img
                            src={require("../../assets/images/Frame1.png")}
                            className="profile-img"
                            alt="img"
                          /> */}
                        {item.rating !== undefined &&
                        item.rating !== null &&
                        item.rating !== "" ? (
                          <h4> {item.rating}</h4>
                        ) : (
                          <h4>" "</h4>
                        )}
                      </div>
                      {item.movieLength !== undefined &&
                      item.movieLength !== null &&
                      item.movieLength !== "" ? (
                        <h5> {secondsToHourse(item.movieLength)} </h5>
                      ) : (
                        <h5>" "</h5>
                      )}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  }
}

export default PurchaseList;
